import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faArrowRightToBracket, faCogs, faDownload, faEdit, faAdd, faTrash, faCheck, faPlusSquare, faEye, faSearch
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import Meta from "../../../component/Meta";
import GoogleMapReact from 'google-map-react';
import {Breadcrumb, Button, Card, Modal} from 'flowbite-react';
import SubCategory from './SubCategory';
import { Tooltip, Avatar } from 'flowbite-react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { Datepicker } from 'flowbite-react';
import i18n from "../../../component/i18n";
export default function Stats(props) {

    let { uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const lng = i18n.language;
    const { t } = useTranslation();
    const meta = {
        title: t('Mon livret d\'accueil statistiques')
    }

    const [stats, setStats] = useState([]);
    const [xAxis, setXAxis] = useState([]);
    const [yAxis, setYAxis] = useState([]);
    const [dataset, setDataset] = useState([]);
    const [guidebook, setGuidebook] = useState([]);
    let dateStart = new Date("2024-01-01");
    let dateEnd = new Date("2024-12-31");

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriAPI+'/app/guidebook/show/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setGuidebook(data)
            });


        var raw = JSON.stringify({"guideBookUUID": uuid,"dateStart": dateStart,
            "dateEnd" : dateEnd});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(global.config.uriAPI+'/app/guidebook/stats', requestOptions)
            .then((response) => response.json())
            .then((data) => {

              setXAxis(data.x);
              setYAxis(data.y);

            })
            .catch((err) => {
            });

    }, []);

    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <Meta meta={meta} />
            <div className="w-full">
                <Menu/>
            </div>
            <div className=" sm:container ">
                <Breadcrumb aria-label="Solid background breadcrumb example" className="bg-gray-50 px-5 py-3 dark:bg-gray-800 mt-10">
                    <Breadcrumb.Item href="/app/home" >
                        {t('Accueil')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={"/app/guidebook/show/"+uuid}>{t('Livret')}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t('Statistiques')}</Breadcrumb.Item>
                </Breadcrumb>
                <div className={"bg-gray-100 rounded p-5 mt-10"}>
                    <div className={"font-bold"}>{t('Suivi des visites sur le livret')} : {guidebook.name}</div>
                    <div className={"flex flex-row gap-2 justify-center items-center m-5"}>
                        <span>{t('Entre')}</span>
                    <Datepicker  defaultDate= {new Date(dateStart)}
                                 language={lng}
                                 id={"dateStartPicker"}
                    />
                        <span>{t('et le')} </span>
                        <Datepicker defaultDate={new Date(dateEnd)}
                                    language={lng}
                                    id={"dateEndPicker"}
                        />
                        <Button className={" text-center  col-span-1 border-cyan-700 text-cyan-500  py-1 rounded-md hover:bg-cyan-700  hover:text-white font-semibold p-2 text-xs" }
                        onClick={() => {

                            const dst =new Date(document.getElementById("dateStartPicker").value+" UTC");
                            const det =new Date(document.getElementById("dateEndPicker").value+" UTC");
                            dateStart = dst.toISOString();
                            dateEnd = det.toISOString();
                            var raw = JSON.stringify({"guideBookUUID": uuid,"dateStart": dateStart,
                                "dateEnd" : dateEnd});
                            const myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/json");
                            myHeaders.append('Authorization', 'Bearer '+token)

                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: raw,
                                redirect: 'follow'
                            };
                            fetch(global.config.uriAPI+'/app/guidebook/stats', requestOptions)
                                .then((response) => response.json())
                                .then((data) => {

                                    setXAxis(data.x);
                                    setYAxis(data.y);

                                })
                                .catch((err) => {
                                });
                        }
                        }
                        ><FontAwesomeIcon icon={faSearch} /></Button>
                     </div>
                    <div className={"w-full h-1/2"}>
                        <Line
                            datasetIdKey='id'
                            className={"w-full"}
                            data={{
                                labels: xAxis,
                                datasets: [
                                    {
                                        id: 1,
                                        label: t('Nombre de vues'),
                                        data: yAxis,
                                        fill: true,
                                        borderColor: '#3498db',
                                    }
                                ],
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    )

}