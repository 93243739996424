import {Form, useParams} from "react-router-dom";
import Error from "../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../asset/img/okurensiologo.svg';
import Loginimg from '../asset/img/loginimg.png';
import Lang from '../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {Navigate} from "react-router-dom";
import zxcvbn from 'zxcvbn';


export default function NewPassword(props) {

    const [inputs, setInputs] =  useState({password : '', passwordRepeat : ''});
    const { t } = useTranslation();
    let { token } = useParams();
    const [error, setError] = useState({typeError : '', messageError: '', iconFont : ''});
    const [button, setButton] = useState({disabled : '', loader: ''});
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faArrowRightToBracket} />});
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/validatetoken/'+token, requestOptions)
            .then((response) => response.json())
            .then((data) => {
            })
            .catch((err) => {
                window.location = '/lostpassword';
                return
            });
    }, []);

    const onSubmit = (data) => {

        //event.preventDefault();
        const password = data.password;
        const passwordRepeat = data.passwordRepeat;

        if (password != passwordRepeat) {
            setError(previousState => {
                return { ...previousState, typeError: "danger" }
            });
            setError(previousState => {
                return { ...previousState, messageError: t('Mot de passe non identique')}
            });
        } else {

            const score = zxcvbn(password).score;

            if (score < 3) {
                setError(previousState => {
                    return { ...previousState, typeError: "danger" }
                });
                setError(previousState => {
                    return { ...previousState, messageError: t('Mot de passe trop faible')}
                });
                return;
            } else {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({"password": password, "token": token});

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                setButton(previousState => {
                    return { ...previousState, disabled: 'disabled' }
                });
                setIconButton(previousState => {
                    return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
                });

                fetch(global.config.uriAPI+"/updatepassword", requestOptions)
                    .then((response) =>  {
                        if(response.status != 200) throw new Error(response.status);
                        else return response.json();
                    })
                    .then(result => {

                        window.location.href = "/login";
                        return;
                    })
                    .catch((error) =>  {
                        setError(previousState => {
                            return { ...previousState, typeError: "warning" }
                        });
                        setError(previousState => {
                            return { ...previousState, messageError: t("Une erreur est ruvenue")}
                        });

                        setError(previousState => {
                            return { ...previousState, iconFont:  <FontAwesomeIcon icon={faCircleExclamation} />}
                        });
                        setButton(previousState => {
                            return { ...previousState, disabled: '' }
                        });
                        setIconButton(previousState => {
                            return { ...previousState, icon:  <FontAwesomeIcon icon={faArrowRightToBracket} />}
                        });
                    });
            }
        }
    }

    return (
        <section className="bg-cyan-300 min-h-screen flex items-center justify-center">
            <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center">
                <div className="md:w-1/2 px-8 md:px-16">
                    <img src={Logo} />
                    <Error typeError={error.typeError} message={error.messageError} iconFont={error.iconFont} />
                    <Form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className="mt-3">
                            <label htmlFor="password" className={ errors.password ?  "block text-sm mb-2 text-red-700" : "block text-sm mb-2"}>
                                {t('Mot de passe')}
                            </label>
                            <input type="password" id="password"
                                   defaultValue={inputs.password|| ""}
                                   onChange={handleChange}
                                   aria-invalid={errors.password ? "true" : "false"}
                                   {...register("password", { required: true })}
                                   className= { errors.password ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                   }
                                   placeholder={t('password')}/>
                            {errors.password && errors.password.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                        </div>
                        <div className="mt-3">
                            <label htmlFor="passwordRepeat" className={ errors.passwordRepeat ?  "block text-sm mb-2 text-red-700" : "block text-sm mb-2"}>
                                {t('Répéter le Mot de passe')}
                            </label>
                            <input type="password" id="passwordRepeat"
                                   defaultValue={inputs.passwordRepeat|| ""}
                                   onChange={handleChange}
                                   aria-invalid={errors.passwordRepeat ? "true" : "false"}
                                   {...register("passwordRepeat", { required: true })}
                                   className= { errors.passwordRepeat ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                   }
                                   placeholder={t('password')}/>
                            {errors.passwordRepeat && errors.passwordRepeat.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                        </div>
                        <div className="mt-5">
                            <button type="submit" disabled={button.disabled}
                                    className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                                {iconButton.icon}&nbsp;&nbsp;{t('Modifier')}
                            </button>
                        </div>
                        <div className="mt-3 flex justify-between items-center">
                            <div>
                                <a href="/login" className="text-black py-1 w-full text-xs
                                 font-semibold">{t('Me connecter')}?</a>
                            </div>
                        </div>
                    </Form>
                    <Lang />
                </div>
                <div className="md:block hidden w-1/2">
                    <img className="rounded-2xl"
                         src={Loginimg} />
                </div>
            </div>
        </section>
    )
}