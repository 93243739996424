import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import {Breadcrumb, FileInput, Label} from "flowbite-react";
import DiscountCodeNew from './discountCode/new';
import DiscountCodeEdit from './discountCode/edit';


export default function DiscountCode(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    let { guideBookUuid, uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [contentType, setContentType] = useState('');

    const { t } = useTranslation();
    const meta = {
        title: t('Ajouter un code de promotion')
    }
    const [discountCodeMode, setDiscountCodeMode] = useState('');
    const [discountCode, setDiscountCode] = useState([]);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriAPI+'/app/discountcode/show/'+uuid, requestOptions)
            .then((response) =>  { if (response.status == 200 )  { response.json() } else { throw new Error('error or empty') } } )
            .then((data) => {

                setDiscountCode(data);
                setDiscountCodeMode('update');
            })
            .catch((err) => {
                setDiscountCodeMode('add');
            });
    }, []);

    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <Meta meta={meta} />
            <div className="w-full">
                <Menu/>
            </div>
            <div className=" sm:container mx-auto w-full">
                <Breadcrumb aria-label="Solid background breadcrumb example" className="bg-gray-50 px-5 py-3 dark:bg-gray-800">
                    <Breadcrumb.Item href="/app/index" >
                        {t('Accueil')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={"/app/guidebook/show/"+guideBookUuid}>{t('Livret')}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t('Code de promotion')}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className={"p-10 rounded bg-gray-200 mt-10"}>
                {  discountCodeMode === "add" ? <DiscountCodeNew  topicUuid={uuid} guideBookUuid={guideBookUuid}></DiscountCodeNew>: <></>}
                {  discountCodeMode === "update" ? <DiscountCodeEdit  topicUuid={uuid} guideBookUuid={guideBookUuid}></DiscountCodeEdit>: <></>}
            </div>
        </section>
    )
}