import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleExclamation, faForward, faBook, faSpinner, faEdit} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";

export default function EditGuideBook(props) {

    let { uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';

    const { t } = useTranslation();
    const meta = {
        title: t('Mon livret d\'accueil')
    }

    const [infoPlace, setInfoPlace] = useState(null);
    const [guidebook, setGuidebook] = useState([]);
    const [inputs, setInputs] =  useState({address : '' , contactEmail: '', contactPhone: '', name: ''});
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriAPI+'/app/guidebook/show/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setGuidebook(data)
                setInputs({
                    address : data.address,
                    contactEmail: data.contactEmail,
                    contactPhone: data.contactPhone,
                    name: data.name
                });
                setInfoPlace({label: data.address, value: data.address});
            })
            .catch((err) => {
            });
    }, []);

    const onSubmit = (data) => {
        const contactEmail = data.contactEmail;
        const contactPhone = data.contactPhone;
        const address = infoPlace;
        const name = data.name;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token);

        let latAddress = '';
        let lngAddress = '';

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        var raw = JSON.stringify({"contactEmail": contactEmail,"contactPhone": contactPhone,
            "address" :guidebook.address,
            "uuid" : uuid,
            "longitude": guidebook.longitude, 'latitude': guidebook.latitude, "name": name, "city": guidebook.city, 'cityCode': 0});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/guidebook/update', requestOptions)
            .then((response) => {
                if(response.status != 200) throw new Error(response.status);
                else return response.json();
            })
            .then((data) => {
                window.location.href = "/app/guidebook/show/"+uuid;
                return;
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const lng = i18n.language;
    const [button, setButton] = useState({disabled : '', loader: ''});
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faEdit} />});

    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <Meta meta={meta} />
            <div className="w-full">
                <Menu/>
            </div>
            <div className=" sm:container">
                {guidebook.name !== undefined ? <Form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate id="formCreate">
                    <div className="mt-3 w-full">
                        <label htmlFor="name" className={ errors.name ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Le nom de votre livret')}</label>
                        <input type="text" id="name"
                               name =",ame"
                               aria-invalid={errors.name ? "true" : "false"}
                               defaultValue={inputs.name|| ""}
                               {...register("name", { onChange: handleChange, required: true})}
                               className= { errors.name ?
                                   "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                   "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 w-full"
                               }
                               placeholder={t("Le gite de la côte fleuris")}/>
                        {errors.name && errors.name.type === "required" && (
                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                        )}
                    </div>
                    <div className="mt-3">
                        <label htmlFor="email" className={ errors.contactEmail ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Votre adresse email de contact')}</label>
                        <input type="text" id="contactEmail"
                               name ="email"
                               aria-invalid={errors.contactEmail ? "true" : "false"}
                               defaultValue={inputs.contactEmail|| ""}
                               {...register("contactEmail", { onChange: handleChange, required: true , pattern : /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g})}
                               className= { errors.contactEmail ?
                                   "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                   "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                               }
                               placeholder={t("contact@contact.com")}/>
                        {errors.contactEmail && errors.contactEmail.type === "required" && (
                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                        )}
                        {errors.contactEmail && errors.contactEmail.type === "pattern" && (
                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Mauvais format')}</div>
                        )}
                    </div>
                    <div className="mt-3">
                        <label htmlFor="telephone" className={ errors.contactPhone ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Votre numéro de téléphone de contact')}</label>
                        <input type="text" id="contactPhone"
                               name ="telephone"
                               aria-invalid={errors.contactPhone ? "true" : "false"}
                               defaultValue={inputs.contactPhone|| ""}
                               {...register("contactPhone", { onChange: handleChange, required: true})}
                               className= { errors.contactPhone ?
                                   "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                   "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                               }
                               placeholder={t("000000000")}/>
                        {errors.contactPhone && errors.contactPhone.type === "required" && (
                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                        )}

                    </div>
                    <div className="mt-5">
                        <button type="submit" disabled={button.disabled}
                                className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                            {iconButton.icon}&nbsp;&nbsp;{t('Modifier')}
                        </button>

                    </div>
                </Form> : <FontAwesomeIcon icon={faSpinner} /> }

            </div>
        </section>
    );
}