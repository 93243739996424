import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../public/locale/en/translation.json';
import translationFR from '../public/locale/fr/translation.json';

i18n
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            fr: {
                translation: translationFR
            },
            en: {
                translation: translationEN
            },
        },
        lng: (window && window.locale) || 'fr',
        fallbackLng: 'fr',
        keySeparator: false, // we do not use keys in form messages.welcome
        saveMissing: true,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            transSupportBasicHtmlNodes: true
        },
    });

export default i18n;