import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleExclamation, faForward, faBook, faSpinner} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Form } from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import * as amplitude from '@amplitude/analytics-browser';
import LogoAnime from "../../../asset/img/logoanime.gif";

export default function Abonnement(props) {
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';

    const { t } = useTranslation();
    const [user, setUser] = useState([]);
    const [plan, setPlan] = useState([]);
    const meta = {
        title: t('Abonnement Okurensio')
    }
    useEffect(() => {


        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/user/profile', requestOptions)
            .then((response) => {
                if(response.status != 200) throw new Error(response.status);
                else return response.json();
            })
            .then((data) => {
                setUser(data);
            })
            .catch((err) => {
            });


        fetch(global.config.uriAPI+'/app/plan/show', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setPlan(data);

                var sc = document.createElement("script");
                sc.setAttribute("defer", true);
                sc.setAttribute("src", "https://js.stripe.com/v3/pricing-table.js");
                document.getElementById("stripe").appendChild(sc);
            })
            .catch((err) => {
            });
    },[]);
    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <Meta meta={meta} />
            <div className="w-full">
                <Menu/>
            </div>
            <div className=" sm:container">

                {plan.length >0 ?
                    <>
                    </>
                    : <>
                        <div className={"mt-10 flex flex-col gap-5"}>
                            <span>{t('Vous avez un abonnement en cours')} </span>
                            <span><b>{t('Date de début')} :</b> {plan.dateStart} </span>
                            <span><b>{t('Date de fin')} :</b> {plan.dateEnd} </span>
                            <span><b>{t('Nombre de livret disponible')} :</b> {plan.nbGuidebook} </span>
                            <span><b>{t('Prix')} :</b> {plan.amount/100} {plan.currency}</span>
                        </div>
                    </>}
                <>
                    <br />
                    <div id="stripe">
                    </div>
                    <stripe-pricing-table pricing-table-id="prctbl_1PXpIAH26k5OWOLVkXaZcNYH"
                                          client-reference-id={user.uuid}
                                          publishable-key="pk_live_51OgNHmH26k5OWOLVpnUw6g55k9BeUc9AmPHdIOk6iiveVWCJLc9VmI4kdXZJ83LzCZ98JzEmbpAs4GvbMcYzQRvb0073rnOPYi">
                    </stripe-pricing-table>
                </>

            </div>
        </section>
    )
}