import i18n from "./i18n";
import React,{Component}  from 'react';
import FrFlag from '../asset/img/fr.jpg';
import EnFlag from '../asset/img/en.jpg';
import {changeLanguage} from "i18next";

function lang() {
    const lng = i18n.language;

    function onChangeLang (langStr) {
        i18n.changeLanguage(langStr);
    };

    return (
        <div className={"flex gap-2 justify-center items-center m-5"}>
            <a href="#" onClick={() => onChangeLang('fr')} className={"" +
                `${lng === 'fr' ? "border-2 rounded-full border-gray-300": "rounded-full hover:rounded-full hover:border-gray-300  hover:border-2"}`}
            >
                <img src={FrFlag} className={"w-4 h-4 rounded-full shadow-lg  m-1"} /></a>
            <a href="#" onClick={() => onChangeLang('en')} className={"" +
                `${lng === 'en' ? "border-2 rounded-full border-gray-300": "rounded-full hover:rounded-full hover:border-gray-300  hover:border-2"}`}
            >
                <img src={EnFlag} className={"w-4 h-4 rounded-full shadow-lg m-1"} /></a>
        </div>
    )
}

export default lang;