//template
import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faArrowRightToBracket
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";

import { Form } from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import {
    useParams
} from "react-router-dom";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyCustomUploadAdapterPlugin from "../../../component/PluginUploader";
import LogoAnime from "../../../asset/img/logoanime.gif";



export default function LCDTemplate(props) {

    let { uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';

    const { t } = useTranslation();
    const [inputs, setInputs] =  useState({
        ssid: props.ssid, password: props.password
    });
    const [summary, setSummary] = useState('');
    const [checkInDetails, setCheckInDetails] = useState('');
    const [checkOutDetails, setCheckOutDetails] = useState('');
    const [prepare, setPrepare] = useState(0);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }

    const meta = {
        title: t('Livret étape 2')
    }
    const [button, setButton] = useState({disabled : '', loader: ''});
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faBook} />});
    const onSubmit = (data) => {
            const name = data.name;
            const contactEmail = data.contactEmail;
            const contactPhone = data.contactPhone;
            const ssid = data.ssid;
            const password = data.password;

            setPrepare(1);

            if (summary === '' ) {
                errors.summary = {type : 'required'};
            }

            if (checkInDetails === '' ) {
                errors.checkIn = {type : 'required'};
            }

            if (checkOutDetails === '' ) {
                errors.checkOut = {type : 'required'};
            }


        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var raw = JSON.stringify({
            "ssid": ssid,
            "password" : password,
            "welcomeSummary": summary,
            "checkInDetails": checkInDetails,
            "checkOutDetails": checkOutDetails
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        fetch(global.config.uriAPI+"/app/guidebook/template/"+uuid, requestOptions)
            .then((response) => {
                if(response.status != 200) throw new Error(response.status);
                else return response.json();
            })
            .then(result => {
                window.location.href = "/app/guidebook/show/"+result.uuid;
                return;
            })
            .catch( (error) => {

            } );
    }

    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <Meta meta={meta} />
            <div className="w-full">
                <Menu/>
            </div>
            <div className=" sm:container">
                {prepare === 0 ?
                    <Form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate id="formCreate">
                        <div className="mt-3">
                            <label htmlFor="ssid" className={ errors.ssid ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Nom du signal Wifi')}</label>
                            <input type="text" id="ssid"
                                   name ="ssid"
                                   aria-invalid={errors.ssid ? "true" : "false"}
                                   defaultValue={inputs.ssid|| ""}
                                   {...register("ssid", { onChange: handleChange, required: true})}
                                   className= { errors.ssid ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                   }
                                   placeholder={t("Wifi")}/>
                            {errors.ssid && errors.ssid.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}

                        </div>
                        <div className="mt-3">
                            <label htmlFor="password" className={ errors.password ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Mot de passe du wifi')}</label>
                            <input type="text" id="password"
                                   name ="password"
                                   aria-invalid={errors.password ? "true" : "false"}
                                   defaultValue={inputs.password|| ""}
                                   {...register("password", { onChange: handleChange, required: true})}
                                   className= { errors.password ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                   }
                                   placeholder={t("Mot de passe")}/>
                            {errors.password && errors.password.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}

                        </div>
                        <div className="mt-3 overflow-auto max-h-96">
                            <label className={ errors.summary ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Un message de bienvenue pour vos locataires')}</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                config = {{
                                    extraPlugins: [ MyCustomUploadAdapterPlugin ],
                                    placeholder: t('Présentez vous et votre location'),

                                }}
                                type="text"
                                name="summary"
                                onChange={ ( event, editor ) => { setSummary(editor.getData())} }
                            />
                            {errors.summary && errors.summary.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                        </div>
                        <div className="mt-3 overflow-auto max-h-96">
                            <label className={ errors.checkIn ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}> {t('Décrivez comment venir et la procédure d\'arrivé')}</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                config = {{
                                    extraPlugins: [ MyCustomUploadAdapterPlugin ],
                                    placeholder: t('Indiquez comment venir et les régles d\'entrées')
                                }}
                                type="text"
                                id="checkIn"
                                onChange={ ( event, editor ) => { setCheckInDetails(editor.getData())} }
                            />
                            {errors.checkIn && errors.checkIn.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                        </div>
                        <div className="mt-3 overflow-auto max-h-96">
                            <label className={ errors.checkOut ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Décrivez la procédure pour le départ')}</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                config = {{
                                    extraPlugins: [ MyCustomUploadAdapterPlugin ],
                                    placeholder: t('Indiquez les modalités de sorties')
                                }}
                                type="text"
                                id="checkOut"
                                name="checkOut"
                                onChange={ ( event, editor ) => { setCheckOutDetails(editor.getData())} }
                            />
                            {errors.checkOut && errors.checkOut.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                        </div>
                        <div className="mt-5">
                            <button type="submit" disabled={button.disabled}
                                    className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                                {iconButton.icon}&nbsp;&nbsp;{t('Créer mon livret Etape 2/2')}
                            </button>

                        </div>
                    </Form>
                    : <div>
                        {t('Votre livret est en cours de création, merci de patienter')}
                        <img src={LogoAnime}
                           style={{
                               width: 80,
                               $height: 80
                           }}
                           className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}/></div>}

            </div>
        </section>
    )
}

