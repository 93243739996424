import Error from "../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSquarePlus,
    faLink,
    faTrash,
    faEdit,
    faSpinner,
    faCog,
    faWarning,
    faCircleExclamation, faCheck, faQrcode, faDownload, faChartLine, faCopy, faBook
} from "@fortawesome/free-solid-svg-icons";
import Menu from './menu'
import {Button, Footer, Modal} from 'flowbite-react';
import { Card } from 'flowbite-react';
import QRCode from "react-qr-code";
import { Pagination } from 'flowbite-react';
import {useForm} from "react-hook-form";
import { Toast } from 'flowbite-react';
import * as amplitude from '@amplitude/analytics-browser';
import mixpanel from "mixpanel-browser/src/loader-module";


export default function AdminIndex() {

    mixpanel.init(global.config.mixpanel, {debug: true, track_pageview: true, persistence: 'localStorage'});
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const { t } = useTranslation();
    const [inputs, setInputs] =  useState({search :  ''});
    const [limit, setLimit] = useState(5);
    const [orderBy, setOrderBy] = useState([]);
    const [searchName, setSearchName] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [guideBooks, setGuideBooks] = useState([]);
    const [nbGuideBook, setNbGuideBook] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [refreshView, setRefreshView] = useState(0);
    const [inDelete, setInDelete] = useState(0);
    const [toast, setToast] = useState({message: '', icon: '', color: '' });
    const [uuidDelete, setUuidDelete] = useState('');
    const [plan, setPlan] = useState([]);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const onPageChange = (page) => {
        setCurrentPage(page);
        setRefreshView(1)

    }
    const [totalPage, setTotalPage] = useState(0);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var raw = JSON.stringify({"page": currentPage-1,"limit": limit, "orderBy": orderBy, 'name': searchName});
        var requestOptionsGet = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };


        fetch(global.config.uriAPI+'/app/plan/show', requestOptionsGet)
            .then((response) => response.json())
            .then((data) => {
                setPlan(data);
                if (plan.endPlan === 1) {
                    window.location.href = '/app/plan';
                }

            })
            .catch((err) => {
            });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/guidebook/nb', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setNbGuideBook(data.nb);
                setTotalPage(Math.ceil(data.nb / limit));
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI+'/app/guidebook/', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setRefreshView(0);
                setGuideBooks(data);
            })
            .catch((err) => {
            });

        //
    }, [currentPage, searchName]);

    const openModalDelete = (uuid) => {
        setUuidDelete(uuid);
        setOpenModal(true)
    }
    const deleteCall = () => {
        const uuid = uuidDelete;
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        setInDelete(1);
        var raw = JSON.stringify({"uuid": uuid});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/guidebook/remove', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                var raw = JSON.stringify({"page": currentPage-1,"limit": limit, "orderBy": orderBy, 'name': searchName});
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                fetch(global.config.uriAPI+'/app/guidebook/', requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        setRefreshView(0);
                        setGuideBooks(data);
                        setInDelete(0);
                        setOpenModal(false);
                        setToast({message: t('Le livret a bien été supprimé'), icon: faCheck, color: 'green' });
                    })
                    .catch((err) => {
                    });
            })
            .catch((err) => {
            });
    }

    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <div class="w-full">
                <Menu />
            </div>
            <div class="sm:container mx-auto  p-5">
                <div className={"p-5 bg-cyan-700 text-white rounded text-center "}>
                    <a href={"https://discord.gg/jNF7SYUK"} target={"_blank"}>{t('Rejoignez nous sur Discord en cliquant ICI')}</a>
                </div>
                <div className={"flex items-center flex-col"}>
                    {toast.message != '' ?
                        <Toast className=" m-5 w-full text-center" >
                            <div className={`inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-${toast.color}-100 text-${toast.color}-500 dark:bg-${toast.color}-800 dark:text-${toast.color}-200`}>
                                <FontAwesomeIcon icon={toast.icon}></FontAwesomeIcon>
                            </div>
                            <div className="ml-3 text-sm font-normal">{toast.message}</div>
                            <Toast.Toggle />
                        </Toast>
                        : <></> }
                <div className=" m-5 w-full text-center"  >
                    {nbGuideBook < plan.nbGuidebook ?
                        <a href={"/app/guidebook/add"}
                           className="text-2xl font-bold tracking-tight text-gray-500 hover:text-cyan-600 dark:text-white first-letter:uppercase w-full flex justify-center items-center gap-5">
                            <FontAwesomeIcon icon={faSquarePlus}  size="2x"/> { guideBooks.length > 0 ?  t('Ajouter un livret'): t('Créer mon premier livret')}
                        </a>
                        : <div>{t('Vous avez atteints le nombre maximun de livret')}</div>}

                </div>
                    <div className=" m-5 text-center max-w-full w-1/2"  >

                        <p className="font-normal text-gray-700 dark:text-gray-400 ">
                            <input type="text" id="search"
                                   name ="search"
                                   defaultValue={inputs.search|| ""}
                                   {...register("search", { onChange: (e) => {setSearchName(e.target.value); setRefreshView(1)} })}
                                   className= { errors.search ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded  text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 w-full"
                                   }
                                   placeholder={t("Rechercher")}/>
                        </p>
                    </div>
                    {refreshView ===1 ? <FontAwesomeIcon icon={faSpinner} />:
                        <>
                            {guideBooks.length > 0 ?
                                (
                                    <div className={"max-w-full"}>
                                        {guideBooks.map((guideBook) =>
                                                <div  className="flex flex-col mt-3  shadow p-5 rounded"  >
                                                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white first-letter:uppercase w-full mt-3">
                                                        <FontAwesomeIcon icon={faBook} /> {guideBook.name}
                                                    </h5>
                                                    <p className="font-normal text-gray-700 dark:text-gray-400  grid grid-cols-1 md:grid-cols-3  gap-5 w-full">
                                                    <span className={"flex   flex-col items-center justify-start gap-5 col-span-2 "}>
                                                        <span>{guideBook.address}</span>
                                                        <a href={"https://okurens.io/guidebook/"+guideBook.uuid} target={"_blank"} className={"text-sm"}><FontAwesomeIcon icon={faLink} /> {t('Voir le livret')}</a>
                                                    </span>
                                                    </p>
                                                    <span className={" flex flex-row gap-2  mt-3 flex-wrap"}>
                                                    <a  href={"/app/guidebook/show/"+guideBook.uuid} className={" text-center col-span-1 border-cyan-700 text-cyan-500  py-1 rounded-md hover:bg-cyan-700  hover:text-white font-semibold p-2 text-xs"}>
                                                        <FontAwesomeIcon icon={faEdit} /> {t('Modifier')}
                                                    </a>
                                                    <a  href={"/app/guidebook/settings/"+guideBook.uuid} className={"text-center  col-span-1 border-cyan-700 text-cyan-500  py-1 rounded-md hover:bg-cyan-700  hover:text-white font-semibold p-2 text-xs"}>
                                                        <FontAwesomeIcon icon={faCog} /> {t('Configuration')}
                                                    </a>
                                                    <a  href={"/app/stats/"+guideBook.uuid} className={"text-center  col-span-1 border-cyan-700 text-cyan-500  py-1 rounded-md hover:bg-cyan-700  hover:text-white font-semibold p-2 text-xs"}>
                                                        <FontAwesomeIcon icon={faChartLine} /> {t('Statistique')}
                                                    </a>
                                                    <a  href={"/app/guidebook/add?guideBookToDuplicateUUID="+guideBook.uuid+"&name="+guideBook.name} className={"text-center  col-span-1 border-cyan-700 text-cyan-500  py-1 rounded-md hover:bg-cyan-700  hover:text-white font-semibold p-2 text-xs"}>
                                                        <FontAwesomeIcon icon={faCopy} /> {t('Dupliquer')}
                                                    </a>

                                                    <a href="#" className={" border-2 border-red-700  text-red-700 hover:bg-red-700 hover:text-white red text-xs rounded p-1 text-center"}
                                                       onClick={() => openModalDelete(guideBook.uuid)} data-uuid={guideBook.uuid}><FontAwesomeIcon icon={faTrash} /></a>
                                                    </span>
                                                    {guideBook.code_wifi.ssid == 'ssid' ?
                                                        <div className={"text-red-600 text-sm"}>
                                                            <FontAwesomeIcon icon={faWarning} /> {t('Vous avez pas encore configurer le Wifi pour le livret, cliquez sur modifier => wifi')}</div>
                                                        :  null}
                                                    {guideBook.duplicate.length >0 ? <div><b>{t('Livret enfant dupliqué')} ({guideBook.duplicate.length})</b></div>: <></> }
                                                    {guideBook.duplicate.map((duplicate) =>
                                                            <div className="flex flex-col mt-3  p-5 rounded bg-gray-100" >
                                                                <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white first-letter:uppercase w-full mt-3">
                                                                    <FontAwesomeIcon icon={faBook} /> {duplicate.name}</h3>
                                                                <span className={"flex  flex-col items-center justify-start gap-5 col-span-2 "}>
                                                                    <span>{guideBook.address}</span>
                                                                    <a href={"https://okurens.io/guidebook/"+duplicate.uuid} target={"_blank"} className={"text-sm"}><FontAwesomeIcon icon={faLink} /> {t('Voir le livret')}</a>
                                                                </span>
                                                                <span className={" flex flex-row gap-2  mt-3 flex-wrap"}>
                                                                    <a  href={"/app/guidebook/show/"+duplicate.uuid} className={" text-center col-span-1 border-cyan-700 text-cyan-500  py-1 rounded-md hover:bg-cyan-700  hover:text-white font-semibold p-2 text-xs"}>
                                                                        <FontAwesomeIcon icon={faEdit} /> {t('Modifier')}
                                                                    </a>
                                                                    <a  href={"/app/guidebook/settings/"+duplicate.uuid} className={"text-center  col-span-1 border-cyan-700 text-cyan-500  py-1 rounded-md hover:bg-cyan-700  hover:text-white font-semibold p-2 text-xs"}>
                                                                        <FontAwesomeIcon icon={faCog} /> {t('Configuration')}
                                                                    </a>
                                                                    <a  href={"/app/stats/"+duplicate.uuid} className={"text-center  col-span-1 border-cyan-700 text-cyan-500  py-1 rounded-md hover:bg-cyan-700  hover:text-white font-semibold p-2 text-xs"}>
                                                                        <FontAwesomeIcon icon={faChartLine} /> {t('Statistique')}
                                                                    </a>
                                                                    <a href="#" className={" border-2 border-red-700  text-red-700 hover:bg-red-700 hover:text-white red text-xs rounded p-1 text-center"}
                                                                       onClick={() => openModalDelete(duplicate.uuid)} data-uuid={duplicate.uuid}><FontAwesomeIcon icon={faTrash} /></a>
                                                                </span>

                                                        </div>
                                                    )
                                                    }
                                                </div>
                                        )}
                                        <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup >
                                            <Modal.Header />
                                            <Modal.Body>
                                                <div className="text-center">
                                                    <FontAwesomeIcon icon={faCircleExclamation} className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                                                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                                        {t('Vous confirmer vouloir supprimer ce livret ? ')}
                                                    </h3>
                                                    {inDelete === 0 ?
                                                        <div className="flex justify-center gap-4">
                                                            <a href={"#"}
                                                               data-uuid = {""}
                                                               className={" border-2 border-red-700  text-red-700 red text-xs rounded p-1 flex items-center justify-center hover:bg-red-700 hover:text-white"} color="failure"
                                                               onClick={() => deleteCall()}>
                                                                {t('Oui, supprimer')}
                                                            </a>
                                                            <Button color="gray" onClick={() => setOpenModal(false)}>
                                                                {t('Non')}
                                                            </Button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <FontAwesomeIcon icon={faSpinner} spin size="2x"/> {t('Suppression en cours...')}
                                                        </div>
                                                    }

                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                )
                                :
                                (
                                    <>{nbGuideBook > 0 ? <FontAwesomeIcon icon={faSpinner} spin size="2x"/> : <></> }
                                    </>
                                )

                            }
                        </>
                    }

                    {totalPage > 0 ?
                        <div className="flex overflow-x-auto sm:justify-center">
                            <Pagination currentPage={currentPage} totalPages={totalPage}
                                        previousLabel={t('Précédent')}
                                        nextLabel={t('suivant')}
                                        onPageChange={onPageChange} />
                        </div>
                        :  <p></p>}

                </div>
                <Lang />
            </div>
        </section>
    );
}