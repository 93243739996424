import Error from "../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faForward, faUser, faSpinner} from "@fortawesome/free-solid-svg-icons";

import {
    Avatar,
    Dropdown,
    Navbar,
} from 'flowbite-react';
import mixpanel from "mixpanel-browser/src/loader-module";


export default function Menu() {

    const { t } = useTranslation();
    mixpanel.init(global.config.mixpanel, {debug: true, track_pageview: true, persistence: 'localStorage'});


    ///app/user/profile
    const token = localStorage.getItem('token');
    const [user, setUser] = useState([]);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/user/profile', requestOptions)
            .then((response) => {
                if(response.status != 200) throw new Error(response.status);
                else return response.json();
            })
            .then((data) => {
                setUser(data);
                mixpanel.identify(user.uuid);
            })
            .catch((err) => {
                localStorage.setItem('token', null);
               window.location.href = '/login';
            });

        //
    }, []);

    const logout = () => {
        localStorage.setItem('token', null);
        window.location.href = '/login';
    }

    return (
        <Navbar fluid >
            <Navbar.Brand href="/app/home">
                <img src={Logo} className="mr-3 h-6 sm:h-9" alt={t('okurensio')} />
            </Navbar.Brand>
            <div className="flex md:order-2">
                {
                    user.email != undefined ?
                        (
                            <Dropdown
                                arrowIcon={false}
                                inline
                                label={<FontAwesomeIcon icon={faUser} />}

                            >
                                <Dropdown.Header>
                                    <span className="block truncate text-sm font-medium">{user.email}</span>
                                </Dropdown.Header>
                                <Dropdown.Item href={"/app/password"}>{t('Mon compte')}</Dropdown.Item>
                                <Dropdown.Item href={"/app/plan"}>{t('Abonnement')}</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={logout}>{t('Déconnexion')}</Dropdown.Item>
                            </Dropdown>
                        ):
                        (
                            <FontAwesomeIcon icon={faSpinner} />
                        )
                }

                <Navbar.Toggle />
            </div>
            <Navbar.Collapse>
                <Navbar.Link href="/app/home" active>
                    {t('Accueil')}
                </Navbar.Link>

                <Navbar.Link href="/app/help" active>
                    {t('Aide')}
                </Navbar.Link>
            </Navbar.Collapse>
        </Navbar>
    );
}