import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faWarning,
    faInfo,
    faCircleInfo
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import * as amplitude from '@amplitude/analytics-browser';
import LogoAnime from "../../../asset/img/logoanime.gif";
import mixpanel from "mixpanel-browser/src/loader-module";

import {Label, Select, ToggleSwitch} from 'flowbite-react';
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
export default function AddGuideBook(props) {

    mixpanel.init(global.config.mixpanel, {debug: true, track_pageview: true, persistence: 'localStorage'});
    const [switchPortailCaptif, setSwitchPortailCaptif] = useState(false);
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    //let { guideBookToDuplicateUUID, uuid } = useParams();
   // console.log(guideBookToDuplicateUUID);
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    //const [searchParams] = useSearchParams();
        //console.log(searchParams);
    const duplicateUUID = params.get("guideBookToDuplicateUUID");
    const nameToDuplicate = params.get("name");

    const { t } = useTranslation();
    const [infoPlace, setInfoPlace] = useState(null);
    const [inputs, setInputs] =  useState({address : props.address , contactEmail: props.contactEmail, contactPhone: props.contactPhone, name: props.name, radius: props.radius});
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const [listeGuideBook, setListeGuideBook] = useState([]);
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const lng = i18n.language;
    const [button, setButton] = useState({disabled : '', loader: ''});
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faBook} />});
    const meta = {
        title: t('Créer un un livret de bienvenue ')
    }

    const [prepare, setPrepare] = useState(0);

    const onSubmit = (data) => {
        const contactEmail = data.contactEmail;
        const contactPhone = data.contactPhone;
        const radius= data.radius;
        const ssid = data.ssid;
        const password = data.password;
        const portailCaptif =  switchPortailCaptif?1:0;
        const address = infoPlace;
        const name = data.name;

        setPrepare(1);

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token);

        let latAddress = '';
        let lngAddress = '';

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        geocodeByAddress(address.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {

                    latAddress = lat;
                    lngAddress = lng;

                    if (duplicateUUID != undefined) {
                        var raw = JSON.stringify({"contactEmail": contactEmail,"contactPhone": contactPhone,
                            "address" :address.label,"radius": radius,"guideBookToDuplicateUUID": duplicateUUID,
                            "ssid": ssid, "password": password, "portailCaptif": portailCaptif,
                            "longitude": lngAddress, 'latitude': latAddress, "name": name, "city": address.label, 'cityCode': 0});
                    } else {
                        var raw = JSON.stringify({"contactEmail": contactEmail,"contactPhone": contactPhone,
                            "address" :address.label,"radius": radius,
                            "ssid": ssid, "password": password, "portailCaptif": portailCaptif,
                            "longitude": lngAddress, 'latitude': latAddress, "name": name, "city": address.label, 'cityCode': 0});
                    }

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(global.config.uriAPI+'/app/guidebook/create', requestOptions)
                    .then((response) => {
                        if(response.status != 200) throw new Error(response.status);
                        else return response.json();
                    })
                    .then((data) => {
                        const uuid = data.uuid;

                        const myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        myHeaders.append('Authorization', 'bearer '+global.config.strapi);

                        var rawStrapi = JSON.stringify({
                            "data" : {
                                "uuid" : uuid, "adresse": address.label, "name": name, "email": contactEmail
                            }
                        });

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body:  rawStrapi,
                            redirect: 'follow'
                        };

                        fetch(global.config.uriStrapi+'/digitalbooks', requestOptions)
                            .then((response) => {
                                if(response.status != 200) throw new Error(response.status);
                                else return response.json();
                            })
                            .then((data) => {
                                window.location.href = "/app/guidebook/settings/"+uuid;
                                return;
                            });

                    })
                    .catch((err) => {

                    });
                }
            )

    }



    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <Meta meta={meta} />
            <div className="w-full">
                <Menu/>
            </div>
            <div className=" sm:container bg-gray-100 rounded p-5">
                {prepare === 0 ?
                    <Form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate id="formCreate">
                        {nameToDuplicate !=undefined ?
                            <div className={"bg-yellow-50 p-5 rounded text-yellow-900"}><FontAwesomeIcon icon={faWarning} /> {t('Vous allez dupliquer le livret à partir du livret')} : {nameToDuplicate}</div>
                            : <></> }
                        <div className="mt-3 w-full">
                            <label htmlFor="name" className={ errors.name ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 font-bold"}>{t('Le nom de votre livret')}</label>
                            <input type="text" id="name"
                                   name ="name"
                                   aria-invalid={errors.name ? "true" : "false"}
                                   defaultValue={inputs.name|| ""}
                                   {...register("name", { onChange: handleChange, required: true})}
                                   className= { errors.name ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 w-full"
                                   }
                                   placeholder={t("Le gite de la côte fleuris")}/>
                            {errors.name && errors.name.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                        </div>
                        <div className="mt-3">
                            <label htmlFor="email" className={ errors.contactEmail ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 font-bold"}>{t('Votre adresse email de contact')}</label>
                            <input type="text" id="contactEmail"
                                   name ="email"
                                   aria-invalid={errors.contactEmail ? "true" : "false"}
                                   defaultValue={inputs.contactEmail|| ""}
                                   {...register("contactEmail", { onChange: handleChange, required: true , pattern : /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g})}
                                   className= { errors.contactEmail ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                   }
                                   placeholder={t("contact@contact.com")}/>
                            {errors.contactEmail && errors.contactEmail.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                            {errors.contactEmail && errors.contactEmail.type === "pattern" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Mauvais format')}</div>
                            )}
                        </div>
                        <div className="mt-3">
                            <label htmlFor="telephone" className={ errors.contactPhone ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 font-bold"}>{t('Votre numéro de téléphone de contact')}</label>
                            <input type="text" id="contactPhone"
                                   name ="telephone"
                                   aria-invalid={errors.contactPhone ? "true" : "false"}
                                   defaultValue={inputs.contactPhone|| ""}
                                   {...register("contactPhone", { onChange: handleChange, required: true})}
                                   className= { errors.contactPhone ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                   }
                                   placeholder={t("000000000")}/>
                            {errors.contactPhone && errors.contactPhone.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}

                        </div>
                        <div className="mt-3">
                            <label htmlFor="ssid" className={ errors.ssid ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 font-bold"}>{t('Nom du réseaux Wifi')}</label>
                            <input type="text" id="ssid"
                                   name ="ssid"
                                   aria-invalid={errors.ssid ? "true" : "false"}
                                   defaultValue={inputs.ssid|| ""}
                                   {...register("ssid", { onChange: handleChange, required: true})}
                                   className= { errors.ssid ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                   }
                                   placeholder={t("Wifi")}/>
                            {errors.ssid && errors.ssid.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                        </div>
                        <div className="mt-3 w-full">
                            <label htmlFor="" className={"block text-sm mb-2 font-bold"}>{t('Portail captif')}</label>
                            <ToggleSwitch checked={switchPortailCaptif} name="portailCaptif" label="Oui j'ai un portail captif pour gérer mon Wifi" onChange={setSwitchPortailCaptif} />
                        </div>
                        <a href={"https://okurens.io/article/3/quel-sont-les-regles-pour-proposer-du-wifi-dans-mon-logement-en-llocation-courte-dur%C3%A9e"}  className={"mt-5 text-blue-500"} target={"_blank"}>{t('En savoir plus sur la réglementation Wifi')}</a>

                        <div className="mt-3">
                            <label htmlFor="password" className={ errors.password ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 font-bold"}>{t('Mot de passe du Wifi')}</label>
                            <input type="text" id="password"
                                   name ="password"
                                   aria-invalid={errors.password ? "true" : "false"}
                                   defaultValue={inputs.password|| ""}
                                   {...register("password", { onChange: handleChange})}
                                   className= { errors.password ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                   }
                                   placeholder={t("Mot de passe")}/>
                            {errors.password && errors.password.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                        </div>
                        <div className="mt-3">
                            <label htmlFor="adresse" className={ errors.address ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 font-bold"}>{t('L\'adresse de votre bien')}</label>

                            <GooglePlacesAutocomplete apiKey={global.config.apiKeyGoogle}
                                                      apiOptions={{ language: lng }}

                                                      selectProps={{
                                                          infoPlace,
                                                          onChange: setInfoPlace,
                                                      }}
                            />
                            {errors.address && errors.address.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                        </div>
                        {duplicateUUID != null ? <></>:
                            <div className={"mt-3"}>
                                <div className="max-w-md">
                                    <div className="mb-2 block">
                                        <Label htmlFor="radius" className={"font-bold"} value={t('Sélectionnez les restaurants et activités dans un rayon de ? ')} />
                                    </div>
                                    <Select id="radius"
                                            {...register("radius", { onChange: handleChange, required: true})}
                                            required>
                                        <option value="10000">10 km</option>
                                        <option value="20000">20 km</option>
                                        <option value="30000"> 30 km </option>
                                        <option value="40000" selected={"selected"}>50 km</option>
                                    </Select>
                                </div>
                                <div className={"bg-yellow-50 text-yellow-800 p-5 text-xs rounded flex flex-col gap-5 mt-5"}>
                                    <span> <FontAwesomeIcon icon={faCircleInfo}></FontAwesomeIcon> {t('Le livret sera automatiquement remplis avec les catégories suivante situé dans le rayon de recherche.')}</span>
                                    <span> - {t('Restaurants / Fast Food')}</span>
                                    <span> - {t('Parc d\'attraction, parc Zoologique, musée, lieux touristique')}</span>
                                    <span> <FontAwesomeIcon icon={faWarning}></FontAwesomeIcon> {t('vous pourrez choisir de les supprimer ou de les modifier par la suite')}</span>
                                </div>
                            </div>

                        }

                        <div className="mt-5">
                            <button type="submit" disabled={button.disabled}
                                    className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                                {iconButton.icon}&nbsp;&nbsp;{t('Créer mon livret')}
                            </button>

                        </div>
                    </Form>
                    :
                    <div>
                        {t('Votre livret est en cours de création, merci de patienter')}
                        <img src={LogoAnime}
                             style={{
                                 width: 80,
                                 $height: 80
                             }}
                             className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}/></div>
                }

            </div>
        </section>
    );
}