import Logo from '../asset/img/okurensiologo.svg';
import Loginimg from '../asset/img/loginimg.png';
import { Form } from "react-router-dom";
import Error from "../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Accordion, Card, Navbar} from 'flowbite-react';

import  Meta  from "../component/Meta";


import Img1 from '../asset/img/architecture-2260836_640.jpg';
import Img2 from '../asset/img/hot-air-balloons-4561267_640.jpg';
import Img3 from '../asset/img/language-2345801_640.jpg';
import Img4 from '../asset/img/qrcode.jpg';
import Demo1 from '../asset/img/demo1.png';
import Demo2 from '../asset/img/demo2.png';
import i18n from "../component/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowRight, faSpinner} from "@fortawesome/free-solid-svg-icons";
import TextTruncate from 'react-text-truncate';
import { Pagination } from 'flowbite-react';
import Lang from '../component/lang.jsx'
import CookieConsent from "react-cookie-consent";
export default function Articles() {

    const { t } = useTranslation();

    const font = {
        fontFamily: 'Inter',
    }

    const meta = {
        title: t('Comment bien gérer sa location courte durée | Okurensio'),
        description: t('Comment bien gérer sa location courte durée | Okurensio'),
        image: Demo1
    }
    const lng = i18n.language;
    const [article, setArticle] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    const onPageChange = (page) => setCurrentPage(page);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+global.config.strapi)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriStrapi+'/articles?locale='+lng+'&populate=*&pagination[page]='+currentPage, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setArticle(data.data);
                setLoading(true)
                setTotalPage(data.meta.pagination.pageCount);
            })
            .catch((err) => {
            });


    }, [lng,currentPage]);


    return (
        <section  className="bg-hray-100 min-h-screen items-center  flex flex-col ">
            <Meta meta={meta} />
            <div
                style = {{
                    fontWeight: 800
                }}
                className={"text-center p-5 w-full bg-cyan-700 text-white text-2xl"}>
                {t('Gratuit à vie pour les 100 premiers inscrits')} <a href={"/register"}><FontAwesomeIcon icon={faCircleArrowRight} /></a>
            </div>
            <div className={"flex justify-end gap-5 items-end w-full mt-10 mr-20"}>
                <a href={"/"} className={"hover:bg-cyan-700 text-cyan-700 hover:rounded  hover:text-white p-2 text-xs"}>{t('Accueil')}</a>
                <a href={"/login"} className={"hover:bg-cyan-700 text-cyan-700 hover:rounded  hover:text-white p-2 text-xs"}>{t('Me connecter')}</a>
                <a href={"/articles"} className={"hover:bg-cyan-700 text-cyan-700 hover:rounded hover:text-white p-2 text-xs"}>{t('Articles')}</a>
            </div>

            <div
                className="flex rounded-2xl  p-5 items-center flex-col container mt-10 mb-10">
                    {loading ?
                            article.map((item, index) =>
                                <Card className="max-w-sm mt-5" imgSrc={global.config.urlStrapi+item.attributes.image.data[0].attributes.formats.thumbnail.url} horizontal>
                                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                        {item.attributes.title}
                                    </h5>
                                    <p className="font-normal text-gray-700 dark:text-gray-400">
                                        <TextTruncate
                                            line={3}
                                            element="span"
                                            truncateText="…"
                                            text={item.attributes.descriptionCourte}
                                            textTruncateChild={<a href={"/article/"+item.id+'/'+item.attributes.slug} className={"text-cyan-700 hover:text-cyan-600"}>{t('Lire la suite')}</a>}
                                        />
                                    </p>
                                    </Card>
                            )

                        : <FontAwesomeIcon icon={faSpinner} spin={true} />}

                    <div className="flex overflow-x-auto sm:justify-center">
                        <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={onPageChange} />
                    </div>
                    <Lang />
                <a href={"/cgv"} className={"hover:bg-cyan-700 text-cyan-700 hover:rounded hover:text-white p-2 text-xs"}>{t('Mentions légales')}</a>
                </div>
            <CookieConsent
                location="bottom"
                buttonText={t('Accepter')}
                cookieName="okurensio"
                style={{ background: "#2B373B" }}
                buttonStyle={{ background: "#0097B2" , color: "#ffffff", fontSize: "13px" }}
                expires={150}
            >
                {t('Nous utilitons des cookies pour améliorer votre expérience d\'utilisation')}.{" "}
                <span style={{ fontSize: "10px" }}></span>
            </CookieConsent>
        </section>
    );
}