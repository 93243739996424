import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faArrowRightToBracket,
    faAdd, faPlusSquare, faEdit, faTrash, faArrowDown, faCaretDown, faCaretUp, faPercent
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";

import { Form } from "react-router-dom";
import i18n from "../../../component/i18n";
import { Avatar } from 'flowbite-react';
import Topic from './topic';
import { Tooltip } from 'flowbite-react';

export default function SubCategory(props) {
    const categoryUUid = props.categoryUuid;
    const guideBookUuid = props.guideBookUuid;
    const token = localStorage.getItem('token');
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [topicList, setTopicList] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [orderBy, setOrderBy] = useState([]);
    const [searchName, setSearchName] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var raw = JSON.stringify({"page": page,"limit": limit, "orderBy": orderBy, 'name': searchName});
        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/subcategory/'+categoryUUid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setSubCategoryList(data.subCategorys)
                setTopicList(data.topic)
            })
            .catch((err) => {
            });
    }, []);


    const viewTopic = (uuid) => (e) => {
        e.preventDefault();
        var x = document.getElementById("subTopic_"+uuid);
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";

        }
    }

    return (
        <div className={"ml-5"}>
            <div className={"flex flex-row gap-2"}>
                <Tooltip content={t('Modifier')}>
                    <a href={"/app/guidebook/editcategory/"+guideBookUuid+"/"+categoryUUid}
                       className={"text-xs  w-fit bg-gray-500 text-white p-1 rounded"}><FontAwesomeIcon icon={faEdit} /> {t('Modifier')}</a>
                </Tooltip>
                {topicList.uuid ==undefined ?   <Tooltip content={t("Ajouter une sous categorie")}>
                    <a href={"/app/guidebook/addsubcategory/"+guideBookUuid+"/"+categoryUUid}
                       className={"text-xs  w-fit bg-gray-500 text-white p-1 rounded"} ><FontAwesomeIcon icon={faPlusSquare}  /> {t('Ajouter une sous-catégorie')}</a>
                </Tooltip>: <></>}
                {topicList.uuid == undefined &&  subCategoryList.length == 0 ? <Tooltip content={t("Ajouter un contenu")}>
                    <a href={"/app/guidebook/addtopic/"+guideBookUuid+"/"+categoryUUid}
                       className={"text-xs  w-fit bg-gray-500 text-white p-1 rounded"} ><FontAwesomeIcon icon={faPlusSquare}  /> {t('Ajouter un contenu')}</a>
                </Tooltip>: <></>}

                <Tooltip content={t("Supprimer")}>
                    <a href={"#"} className={"text-xs  w-fit bg-red-700 text-white p-1 rounded"} onClick={() => props.openModalDelete("/app/category/remove/"+categoryUUid)}><FontAwesomeIcon icon={faTrash} /></a>
                </Tooltip>
            </div>
            {subCategoryList.map((sub) => {
                    return (
                        <div>
                            <div className={"flex gap-5 m-2 items-center bg-gray-300 p-2 rounded text-gray-700"}>
                                <Avatar img={global.config.uriAPI+'/'+sub.mediaUrl} />
                                <div className={"flex flex-col gap-2 justify-end w-full"}>
                                    <a href={"#"} className={"first-letter:uppercase flex flex-row gap-2"} onClick={viewTopic(sub.uuid)}>
                                        <div className={"first-letter:uppercase w-full"}>{sub.name}</div>
                                        <div className={"flex flex-row justify-end w-full gap-5"}>
                                            <div  style={{fontSize: 10}} id={"subNb_"+sub.uuid} className={"w-fit bg-gray-500 text-white p-1 rounded"}></div>
                                            <FontAwesomeIcon icon={faCaretDown} id={"caret_"+sub.uuid} className={""}/>
                                        </div>

                                    </a>
                                    <div className={"flex flex-row gap-2"}>

                                            <a href={"/app/guidebook/editsubcategory/"+guideBookUuid+"/"+categoryUUid+"/"+sub.uuid}
                                               className={"w-fit p-1 bg-gray-700 text-white rounded text-xs"}
                                            ><FontAwesomeIcon icon={faEdit}/> </a>

                                            <a href={"/app/guidebook/addtopic/"+guideBookUuid+"/"+sub.uuid}
                                               className={"w-fit p-1 bg-gray-700 text-white rounded text-xs"}
                                            ><FontAwesomeIcon icon={faPlusSquare}
                                            /> </a>

                                            <a href={"#"} onClick={() => props.openModalDelete("/app/subcategory/remove/"+sub.uuid)}
                                               className={"w-fit p-1 bg-red-700 text-white rounded text-xs"}
                                            ><FontAwesomeIcon icon={faTrash}/> </a>

                                    </div>

                                </div>


                            </div>
                            <Topic subCategoryUuid={sub.uuid}  display="none" openModalDelete={props.openModalDelete} guideBookUuid = {guideBookUuid}/>
                        </div>
                    )
            }
            )}
            {topicList.uuid !=undefined ?
                <div className={"flex gap-5 m-2 items-center bg-gray-50 p-2 rounded text-gray-700 italic"}>
                    {new RegExp("places.google", 'g').test(topicList.mediaUrl) ?
                        <div style={{
                            backgroundImage: `url(${topicList.mediaUrl})`,
                            backgroundSize: "cover",
                            width: 40,
                            height: 40,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            borderRadius: 10,
                            display: 'block'
                        }}>
                        </div>
                        :
                        <div style={{
                            backgroundImage: `url(${global.config.uriAPI+"/"+topicList.mediaUrl})`,
                            backgroundSize: "cover",
                            width: 40,
                            height: 40,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            borderRadius: 10,
                            display: 'block'
                        }}>
                        </div>}

                    <div className={"flex flex-col gap-4"}>
                        <div className={"flex gap-10"}><b className={"text-xs"}>{topicList.name}</b>
                            {topicList.type === 'google_place' ?
                                <span  className={"bg-blue-300 p-1 text-xs rounded text-white w-fit"}>{topicList.distance !== undefined ? topicList.distance > 1000 ? topicList.distance/1000 + ' km' : topicList.distance + 'm' : <></>}</span>
                                : <></>}
                        </div>
                        {topicList.type === 'google_place' ? <p className={"text-xs"}>{topicList.address}</p>:  <></>}
                        <div className={"flex flex-row gap-2"}>

                            <a href={"/app/guidebook/edittopic/"+props.guideBookUuid+"/"+topicList.uuid}
                               className={"text-white bg-gray-700 p-1 rounded text-xs"} ><FontAwesomeIcon icon={faEdit}/> </a>

                            <a href={"/app/guidebook/discountcodetopic/"+props.guideBookUuid+"/"+topicList.uuid}
                               className={"text-white bg-gray-700 p-1 rounded text-xs"} ><FontAwesomeIcon icon={faPercent}/> </a>

                            <a href={"#"} className={"text-white bg-red-700 p-1 rounded text-xs"} onClick={() => props.openModalDelete("/app/topic/remove/"+topicList.uuid)}><FontAwesomeIcon icon={faTrash}/> </a>

                        </div>

                    </div>
                </div>
                : <></> }
        </div>
    )
}