import Error from "../../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../../asset/img/loginimg.png';
import Lang from '../../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd, faTrash
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../../component/i18n";
import  Meta  from "../../../../component/Meta";
import {FileInput, Label} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyCustomUploadAdapterPlugin from "../../../../component/PluginUploader";



export default function DiscountCodeEdit(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    const topicUuid = props.topicUuid;
    const guideBookUuid = props.guideBookUuid;
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [contentType, setContentType] = useState('');

    const { t } = useTranslation();
    const meta = {
        title: t('Ajouter un code de promotion')
    }
    const [discountCodeMode, setDiscountCodeMode] = useState('');
    const [discountCode, setDiscountCode] = useState([]);
    const [inputs, setInputs] =  useState({ logo: ''});
    const [loadData, setLoadData] = useState(false);
    const [button, setButton] = useState({disabled : '', loader: ''});
    const [summary, setSummary] = useState('');
    const [discountCodeUuid , setDiscountCodeUuid] = useState('');
    const [discountCodeData, setDiscountCodeData] = useState([]);
    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faAdd} />});

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriAPI+'/app/discountcode/show/'+topicUuid, requestOptions)
            .then((response) =>  response.json() )
            .then((data) => {

                setDiscountCodeUuid(data.uuid);
                setSummary(data.name);
                setDiscountCodeData(data);
            })
            .catch((err) => {

            });
    }, []);

    const onSubmitContent = (data) => {

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        //myHeaders.append('Content-Type', 'Multipart/form-data');

        let formData = new FormData();

        let fileLogo = document.querySelector('#logo').files[0];
        if (fileLogo) {
            const fileSizeKiloBytesLogo = fileLogo.size / 1024
            const extensionLogo = fileLogo.type;


            if (['image/png', 'image/jpg', 'image/jpeg'].includes(extensionLogo) === false) {

                setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
                return;
            }

            if(fileSizeKiloBytesLogo > MAX_FILE_SIZE){

                setError('logo', { type: 'size', message: t('Le fichier est trop volumineux') });
                return
            }
        }

        setLoadData(false);

        formData.append('discountcode', fileLogo);
        formData.append('discountCodeUuid', discountCodeUuid);
        formData.append('content', summary);


        /*var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };*/

        //var raw = JSON.stringify({"content":summary,"discountCodeUuid": discountCodeUuid,"discountcode":fileLogo});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/discountcode/update', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                window.location.href = "/app/guidebook/show/"+guideBookUuid;
                return;
            })
            .catch((err) => {
            });
    }


    const validLogo = () => {
        let fileData = document.querySelector('#logo').files[0];
        const extension = fileData.type;

        if (['image/png', 'image/jpg', 'image/jpeg'].includes(extension) === false) {
            setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
            return
        }
        setError('logo', null);
    }

    return (
        <>
            <Form action="" method="post" onSubmit={handleSubmit(onSubmitContent)} noValidate id="formCreate" className={"flex flex-col gap-5 mt-5"}>
                <b>{t('Modifier un code de promotion')} : <span className={"first-letter:uppercase"}>{inputs.name}</span> </b>
                <div id="fileUpload" className="w-full">
                    <div className="mb-2 block">
                        <Label htmlFor="file" value={t('Télécharger un bon de reduction')} />
                    </div>
                    <FileInput id="logo"  name={"logo"}
                               {...register("logo", { onChange: validLogo })}
                               helperText={t('Vous pouvez mettre une image qui représente le bon de réduction 400*400 pixel' )} />
                    {errors.logo &&  (errors.logo.type === 'error' ||  errors.logo.type === 'size' ) && (
                        <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {errors.logo.message}</div>
                    )}
                    {discountCodeData.uuid != undefined ?
                        discountCodeData.media.url !== undefined ?
                            <img src={global.config.uriAPI+'/'+discountCodeData.media.url} className={'w-1/4'} />
                            : <></>
                        : <></>}
                </div>
                <div className="mt-3">
                    <label className={ errors.summary ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Les informations sur le code de promotion')}</label>
                    <CKEditor
                        editor={ ClassicEditor }
                        config = {{
                            extraPlugins: [ MyCustomUploadAdapterPlugin ],
                            placeholder: t('Code de promotion'),

                        }}
                        type="text"
                        name="summary"
                        data={summary}
                        onChange={ ( event, editor ) => { setSummary(editor.getData())} }
                    />
                    {errors.summary && errors.summary.type === "required" && (
                        <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                    )}
                </div>
                <button type="submit" disabled={button.disabled}
                        className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                    {iconButton.icon}&nbsp;&nbsp; {t('Modifier')}
                </button>
            </Form>
            <br />
            <a href={""} onClick={(e) => {
                e.preventDefault();
                if(window.confirm(t('Voulez-vous vraiment supprimer ce code de promotion ?'))){
                    const myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append('Authorization', 'Bearer '+token)
                    let formData = new FormData();

                    var requestOptions = {
                        method: 'DELETE',
                        headers: myHeaders,
                        redirect: 'follow'
                    };
                    fetch(global.config.uriAPI+'/app/discountcode/delete/'+discountCodeUuid, requestOptions)
                        .then((response) =>  response.json() )
                        .then((data) => {
                            window.location.href = "/app/guidebook/show/"+guideBookUuid;
                        })
                        .catch((err) => {

                        });
            }}}
                    className="border-2  p-2 border-red-700 w-full mt-10 bg-red-500 text-white py-1 rounded-md hover:bg-transparent hover:text-red-700 font-semibold">
                <FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp; {t('Supprimer')}
            </a>
        </>

    )
}