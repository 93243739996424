import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowAltCircleDown, faChevronLeft,
    faCircleExclamation,
    faCircleInfo,
    faHome,
    faLocationDot,
    faSearch
} from "@fortawesome/free-solid-svg-icons";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {Navigate} from "react-router-dom";
import Meta from "../../component/Meta";
import LangFront from "./langFront";
import {Avatar, Modal} from 'flowbite-react';
import PowerBy from "./powerby";
import LogoAnime from "../../asset/img/logoanime.gif";
import NBar from "./nbar";
import GoogleMapReact from "google-map-react";
import PlaceholderImg from "../../asset/img/placeholder.png";
import LocationImg from "../../asset/img/location.png";

function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

function hexToRGB(hex, alpha) {
    console.log(hex);
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export default function Carte(props) {
    let { uuid } = useParams();
    const [categorys, setCategorys] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const { t } = useTranslation();
    const [guideBook, setGuideBook] = useState([]);
    const [langage, setLangage] = useState([]);
    const langId = localStorage.getItem('langId');
    const [styles, setStyles] = useState({overlay: '', item: '', itemActive: '', navigationBar: ''});
    const [meta, setMeta] = useState({title: '', font: '', description: '', image: ''});
    const [topics, setTopics] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const [topicShow, setTopicShow] = useState([]);

    useEffect(() => {

        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });



        fetch(global.config.uriAPI+'/guidebook/'+uuid, )
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data);

                const updateStyles = {
                    overlay : {
                        position: 'relative'
                    },
                    item: {
                        backgroundColor: data.settings.navigationBarColor,
                    },
                    itemHover:{
                        backgroundColor: adjust(data.settings.navigationBarColor, -40)
                    },
                    header: {
                        backgroundColor: data.settings.navigationBarColor,
                        height: '90px'
                    },
                    itemActive: {
                        backgroundColor: adjust(data.settings.navigationBarColor, -40)
                    },
                    navigationBar: {
                        color: '#fff',
                        fontFamily: data.settings.mainFont,
                        fontSize: '0.7rem',
                        height: '70px'
                    },
                    borderItem: {
                        borderBottom: '1px solid '+data.settings.navigationBarColor,
                    },
                    fontText: {
                        fontFamily: data.settings.mainFont,
                    },
                    textTopic: {
                        fontFamily: data.settings.mainFont,
                        color: data.settings.navigationBarColor
                    },
                    category: {}
                }

                const meta = {
                    title: data.name,
                    font: data.settings.mainFont,
                    description: data.name,
                    image: global.config.uriAPI+'/'+data.media.url
                }
                setMeta(meta);
                /*updateStyles.item[':hover']= {
                    backgroundColor: adjust(data.settings.navigationBarColor, -40)
                }*/
                setStyles(updateStyles);
                fetch(global.config.uriAPI+'/guidebook/map/'+uuid+'/'+langId)
                    .then((response) => response.json())
                    .then((data) => {
                        setTopics(data);
                        setState({ places: data });
                        setLoadData(true);
                    })
                    .catch((err) => {
                    });

            })
            .catch((err) => {
            });

    }, []);

    const Placeholder = ({ img }) => <img src={img} style={{ width:32, height: 'auto'}} />;
    const Location = ({ text }) => <img src={LocationImg} style={{ width:32, height: 'auto'}} />;
    const [state, setState] = useState({ places: [] });
    const Marker = ({ show, place }) => {
        const markerStyle = {
            cursor: 'pointer',
            zIndex: 10,
        };
        
        return (
            <>
                <div style={markerStyle} >
                    <img src={global.config.uriAPI+'/'+place.mediacat_url} style={{ width:32, height: 'auto'}} />
                </div>
            </>
        );
    };

    const onChildClickCallback = (key) => {
            const info = state.places[key];
            fetch(global.config.uriAPI+'/guidebook/topicshow/'+info.uuid+"/"+langId, )
                .then((response) => response.json())
                .then((data) => {
                    setTopicShow(data);
                    // document.getElementById("modal").innerHTML = data.content;
                    setOpenModal(true);
                })
                .catch((err) => {
                });
    }

    return (
        <div className={""}>
            {loadData ? <div style={styles.overlay} >
                    <Meta meta={meta} />
                    <header className={"flex flex-col top-0 justify-around items-center z-1  w-full"}
                            style={styles.header}
                    >
                        <div className={"flex flex-row gap-5 flex-wrap justify-around items-center z-1   w-full"}>
                            {guideBook.logoId !== null ? <img   src={global.config.uriAPI+'/'+guideBook.logo.url}
                                                                style={{
                                                                    width: 40
                                                                }}
                                                                className={""} /> : null}
                            <div className={"flex flex-row justify-start items-center"}>
                                <a href={"/guidebook/"+uuid} className={" rounded w-fit pr-2 pl-2"} style={{
                                    color: guideBook.settings.iconPrimaryColor,
                                    backgroundColor: guideBook.settings.navigationBarColor
                                }}><FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon></a>
                                <LangFront langage={langage} />
                            </div>
                        </div>
                        <div className={"text-white font-bold p-0.5 text-sm"}>
                            {guideBook.name}
                        </div>
                    </header>
                    <section style={{
                        backgroundImage: `linear-gradient(
                  ${hexToRGB(guideBook.settings.mediaOverlay, guideBook.settings.imageOverlayPercentage / 100)},
                ${hexToRGB(guideBook.settings.mediaOverlay, guideBook.settings.imageOverlayPercentage / 100)}),
                url(${global.config.uriAPI+'/'+guideBook.media.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        display: 'block',
                        height: 'calc(100vh - 161px)',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingRight:'15px',
                        paddingLeft: '15px',
                        overflowY: 'scroll'
                    }}
                    >
                        <div
                            style={{

                            }}
                            className={" h-full mb-10"}>
                            <div className={"flex flex-col gap-3 overflow-auto h-full"}>
                                <div style={{width:"100%",height:"100%", borderRadius: "10px;"}}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: global.config.apiKeyGoogle }}
                                        center={[guideBook.latitude, guideBook.longitude]} zoom={9}
                                        onChildClick={onChildClickCallback}
                                    >
                                        {topics.map((topic, index, array) =>
                                            <Marker
                                            key={index}
                                            lat={topic.latitude}
                                            lng={topic.longitude}
                                            show={topic.show}
                                            place={topic}
                                        />
                                        )}
                                        <Location
                                            lat={guideBook.latitude}
                                            lng={guideBook.longitude}
                                            text="My Marker"
                                        />
                                    </GoogleMapReact>
                                </div>

                            </div>
                        </div>

                    </section>
                    <NBar styles={styles} guideBook={guideBook} active={"carte"}/>
                    <Modal show={openModal} onClose={() => setOpenModal(false)}>
                        <Modal.Header>{openModal ?
                            topicShow.name
                            : null}</Modal.Header>
                        <Modal.Body>
                            <div className="space-y-6" id={"modal"}>
                                {openModal ?
                                    <div className={"flex flex-col gap-5"}>
                                        {topicShow.type === 'google_place' ?
                                            <div style={styles.topicDistance} className={"p-1 text-white rounded text-xs w-fit m-2 "}>
                                                {topicShow.type === "google_place" ? topicShow.distance > 1000 ? topicShow.distance/1000 + ' km' : topicShow.distance + 'm'  : null}
                                            </div>
                                            : null }

                                        {topicShow.media != null  ?
                                            <img src={new RegExp("places.google", 'g').test(topicShow.media.url) ? topicShow.media.url : global.config.uriAPI + '/' + topicShow.media.url} className={"w-full h-auto rounded"} />
                                            :null}
                                        <div dangerouslySetInnerHTML={{__html: topicShow.content}} className={"mt-5"}></div>

                                        <div>{topicShow.address !== '' ? topicShow.address: null}</div>
                                        <div>{topicShow.phoneNumber !== '' ? topicShow.phoneNumber: null}</div>
                                        <div>{topicShow.uri !== '' ?
                                            <a href={topicShow.uri} target={"_blank"} style={styles.textTopic} className={""}>{topicShow.uri}</a>
                                            : null}</div>

                                        {topicShow.type === 'google_place' ?
                                            <div style={{width:"100%",height:"250px", borderRadius: "10px;"}}>
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{ key: global.config.apiKeyGoogle }}
                                                    center={[topicShow.latitude, topicShow.longitude]} zoom={11}
                                                >
                                                    <Placeholder
                                                        lat={topicShow.latitude}
                                                        lng={topicShow.longitude}
                                                        text="My Marker"
                                                    />
                                                    <Location
                                                        lat={guideBook.latitude}
                                                        lng={guideBook.longitude}
                                                        text="My Marker"
                                                    />
                                                </GoogleMapReact>
                                            </div>
                                            : null }

                                    </div>
                                    : null}
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                :
                <img src={LogoAnime}
                     style={{
                         width: 80,
                         $height: 80
                     }}
                     className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}/>
            }

        </div>
    )
}