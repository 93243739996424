import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import PlaceholderImg from '../../asset/img/placeholder.png';
import LocationImg from '../../asset/img/location.png';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowAltCircleDown, faArrowLeft, faChevronLeft,
    faCircleExclamation,
    faCircleInfo,
    faHome,
    faLocationDot,
    faSearch
} from "@fortawesome/free-solid-svg-icons";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {Navigate} from "react-router-dom";
import Meta from "../../component/Meta";
import LangFront from "./langFront";
import {Avatar, Button, Modal} from 'flowbite-react';
import LogoAnime from "../../asset/img/logoanime.gif";
import NBar from "./nbar";
import GoogleMapReact from 'google-map-react';


function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

function hexToRGB(hex, alpha) {
    console.log(hex);
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export default function TopicDetail(props) {
    let { topicUuid, uuid } = useParams();
    const [topic, setTopic] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const { t } = useTranslation();
    const [guideBook, setGuideBook] = useState([]);
    const [langage, setLangage] = useState([]);
    const langId = localStorage.getItem('langId');
    const [styles, setStyles] = useState({overlay: '', item: '', itemActive: '', navigationBar: ''});
    const [meta, setMeta] = useState({title: '', font: '', description: '', image: ''});
    const [openModal, setOpenModal] = useState(false);
    const [hoveredItems, setHoveredItems] = useState([]);
    const [topicShow, setTopicShow] = useState([]);


    useEffect(() => {

        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI+'/guidebook/'+uuid, )
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data);

                const updateStyles = {
                    overlay : {
                        position: 'relative'
                    },
                    item: {
                        backgroundColor: data.settings.navigationBarColor,
                    },
                    header: {
                        backgroundColor: data.settings.navigationBarColor,
                        height: '90px'
                    },
                    colorTitle: {
                        color: data.settings.navigationBarColor,
                    },
                    itemHover:{
                        backgroundColor: adjust(data.settings.navigationBarColor, -40)
                    },
                    itemActive: {
                        backgroundColor: adjust(data.settings.navigationBarColor, -40)
                    },
                    navigationBar: {
                        color: '#fff',
                        fontFamily: data.settings.mainFont,
                        fontSize: '0.7rem',
                        height: '70px'
                    },
                    borderItem: {
                        borderBottom: '1px solid '+data.settings.navigationBarColor,
                    },
                    fontText: {
                        fontFamily: data.settings.mainFont,
                    },
                    category: {},
                    topicDistance: {
                        backgroundColor: data.settings.navigationBarColor
                    },
                    textTopic: {
                        fontFamily: data.settings.mainFont,
                        color: data.settings.navigationBarColor
                    }
                }

                const meta = {
                    title: data.name,
                    font: data.settings.mainFont,
                    description: data.name,
                    image: global.config.uriAPI+'/'+data.media.url
                }
                setMeta(meta);
                /*updateStyles.item[':hover']= {
                    backgroundColor: adjust(data.settings.navigationBarColor, -40)
                }*/
                setStyles(updateStyles);

                fetch(global.config.uriAPI+'/guidebook/topicshow/'+topicUuid+"/"+langId, )
                    .then((response) => response.json())
                    .then((data) => {
                        setTopic(data);
                        setLoadData(true);
                    })
                    .catch((err) => {
                    });
            })
            .catch((err) => {
            });
    }, []);

    const handleHover = (index) => {
        return () => {
            setHoveredItems(previousState => {
                return {...previousState, [index]: !previousState[index]}
            })
        }
    };


    const Placeholder = ({ text }) => <img src={PlaceholderImg} style={{ width:32, height: 'auto'}} />;
    const Location = ({ text }) => <img src={LocationImg} style={{ width:32, height: 'auto'}} />;


    return (
        <div className={""}>
            {loadData ? <div style={styles.overlay} >
                    <Meta meta={meta} />
                    <header className={"flex flex-col top-0 justify-around items-center z-1   w-full"}
                            style={styles.header}
                    >
                        <div className={"flex flex-row gap-5 flex-wrap justify-around items-center z-1   w-full"}>
                            {guideBook.logoId !== null ? <img   src={global.config.uriAPI+'/'+guideBook.logo.url}
                                                                style={{
                                                                    width: 40
                                                                }}
                                                                className={""} /> : null}
                            <div className={"flex flex-row justify-start items-center"}>
                                <a href={"/guidebook/subcategory/"+topic.sub_category_uuid+'/'+uuid} className={" rounded w-fit pr-2 pl-2"} style={{
                                    color: guideBook.settings.iconPrimaryColor,
                                    backgroundColor: guideBook.settings.navigationBarColor
                                }}><FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon></a>
                                <LangFront langage={langage} />
                            </div>
                        </div>
                        <div className={"text-white font-bold p-0.5 text-sm"}>
                            {guideBook.name}
                        </div>
                    </header>
                    <section style={{
                        backgroundImage: `linear-gradient(
                  ${hexToRGB(guideBook.settings.mediaOverlay, guideBook.settings.imageOverlayPercentage / 100)},
                ${hexToRGB(guideBook.settings.mediaOverlay, guideBook.settings.imageOverlayPercentage / 100)}),
                url(${global.config.uriAPI+'/'+guideBook.media.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        display: 'block',
                        height: 'calc(100vh - 161px)',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingRight:'15px',
                        paddingLeft: '15px',
                        overflowY: 'scroll'
                    }}
                    >

                        <div
                            style={{

                            }}
                            className={""


                        }>
                            <div className={"flex flex-col gap-3 overflow-auto h-full w-full"}>
                                <div className={"bg-white p-2 rounded shadow hover:bg-gray-200 items-center"}>
                                                <b className={"first-letter:uppercase"} style = {styles.colorTitle}>{topic.name}</b>
                                                <div className={"flex flex-col gap-5"}>
                                                    {topic.type === 'google_place' ?
                                                        <>
                                                        <div style={styles.topicDistance} className={"p-1 text-white rounded text-xs w-fit m-2 "}>
                                                            {topic.type === "google_place" ? topic.distance > 1000 ? topic.distance/1000 + ' km' : topic.distance + 'm'  : null}
                                                        </div>
                                                        {topic.media != null  ?
                                                                <img src={new RegExp("places.google", 'g').test(topic.media.url) ? topic.media.url : global.config.uriAPI + '/' + topic.media.url} className={"w-full h-auto rounded"} />
                                                                : null}
                                                        </>
                                                        : null }

                                                    {/*
                                                    {topic.media != null  ?
                                                        <img src={new RegExp("places.google", 'g').test(topic.media.url) ? topic.media.url : global.config.uriAPI + '/' + topic.media.url} className={"w-full h-auto rounded"} />
                                                        : null} */}
                                                    <div dangerouslySetInnerHTML={{__html: topic.content}} className={"mt-5"}></div>

                                                    <div>{topic.address !== '' ? topic.address: null}</div>
                                                    <div>{topic.phoneNumber !== '' ? topic.phoneNumber: null}</div>
                                                    <div>{topic.uri !== '' ?
                                                        <a href={topic.uri} target={"_blank"} style={styles.textTopic} className={""}>{topic.uri}</a>
                                                        : null}</div>

                                                    {topic.type === 'google_place' ?
                                                        <div style={{width:"100%",height:"250px", borderRadius: "10px;"}}>
                                                            <GoogleMapReact
                                                                bootstrapURLKeys={{ key: global.config.apiKeyGoogle }}
                                                                center={[topic.latitude, topic.longitude]} zoom={11}
                                                            >
                                                                <Placeholder
                                                                    lat={topic.latitude}
                                                                    lng={topic.longitude}
                                                                    text="My Marker"
                                                                />
                                                                <Location
                                                                    lat={guideBook.latitude}
                                                                    lng={guideBook.longitude}
                                                                    text="My Marker"
                                                                />
                                                            </GoogleMapReact>
                                                        </div>
                                                        : null }

                                                </div>
                                            </div>
                            </div>
                        </div>
                        <NBar styles={styles} guideBook={guideBook} active={"guide"}/>
                    </section>
                </div>
                :
                <img src={LogoAnime}
                     style={{
                         width: 80,
                         $height: 80
                     }}
                     className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}/>
            }

        </div>
    )
}