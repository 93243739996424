import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import Logo from '../../asset/img/okurensiologo.svg';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronLeft,
    faCircleExclamation,
    faCircleInfo,
    faHome,
    faLocationDot,
    faSearch,
    faPercent
} from "@fortawesome/free-solid-svg-icons";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {Navigate} from "react-router-dom";
import Meta from "../../component/Meta";
import LangFront from "./langFront";
import LogoAnime from "../../asset/img/logoanime.gif";
import NBar from "./nbar";
import mixpanel from "mixpanel-browser/src/loader-module";
import {faTag} from "@fortawesome/free-solid-svg-icons/faTag";
import {faEuro} from "@fortawesome/free-solid-svg-icons/faEuro";
function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

function hexToRGB(hex, alpha) {

    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export default function GuideBookIndex(props) {

    let { uuid } = useParams();
    const queryParameters = new URLSearchParams(window.location.search)
    const origin = queryParameters.get("origin");

    const [guideBook, setGuideBook] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const [meta, setMeta] = useState({title: '', font: '', description: '', image: ''});
    const [styles, setStyles] = useState({overlay: '', item: '', itemActive: '', navigationBar: ''});
    const [hoveredItems, setHoveredItems] = useState([]);
    const [langage, setLangage] = useState([]);

    const handleHover = (index) => {
           return () => {
                setHoveredItems(previousState => {
                    return {...previousState, [index]: !previousState[index]}
                })
            }
    };

    useEffect(() => {
        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });


        fetch(global.config.uriAPI+'/guidebook/'+uuid+"?origin="+origin, )
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data);

                /*if (data.media.length === 0) {

                } else {*/
                    const updateStyles = {
                        overlay : {
                            position: 'relative',
                        },
                        item: {
                            backgroundColor: data.settings.navigationBarColor,
                        },
                        itemHover:{
                            backgroundColor: adjust(data.settings.navigationBarColor, -40)
                        },
                        itemActive: {
                            backgroundColor: adjust(data.settings.navigationBarColor, -40)
                        },
                        navigationBar: {
                            color: '#fff',
                            fontFamily: data.settings.mainFont,
                            fontSize: '0.7rem',
                            height: '70px'
                        }
                    }
                    /*updateStyles.item[':hover']= {
                        backgroundColor: adjust(data.settings.navigationBarColor, -40)
                    }*/
                    setStyles(updateStyles);
                    setLoadData(true);


            })
            .catch((err) => {
            });
    }, []);
    const { t } = useTranslation();


    return (
        <div className={""}>
        {loadData   ?
            (
                <div style={styles.overlay} >
                    <Meta meta={{
                        title: guideBook.name,
                        font: guideBook.settings.mainFont,
                        description: guideBook.name,
                        image: global.config.uriAPI+'/'+guideBook.media.url
                    }} />

                    {guideBook.media.id != undefined ?
                        <div><section
                            style={{
                                backgroundImage: `linear-gradient(
                  ${hexToRGB(guideBook.settings.mediaOverlay, guideBook.settings.imageOverlayPercentage / 100)},
                ${hexToRGB(guideBook.settings.mediaOverlay, guideBook.settings.imageOverlayPercentage / 100)}),
                url(${global.config.uriAPI+'/'+guideBook.media.url})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                height: 'calc(100vh - 70px)',
                                display: 'block',
                                overflowY: 'scroll'
                            }}
                        >
                            <div className={"flex flex-col justify-start items-center"}>
                                <LangFront langage={langage} />
                                {guideBook.logoId !== null ? <img   src={global.config.uriAPI+'/'+guideBook.logo.url}
                                                                    className={" w-1/4 mt-10 "} /> : null}
                                <div  className={"mt-10 text-white text-4xl   " +
                                    "font-bold first-letter:uppercase text-center w-4/5 sm:w-1/2"} style={{fontFamily: guideBook.settings.mainFont}}>{guideBook.name}</div>
                            </div>

                        </section>
                            <NBar styles={styles} guideBook={guideBook}  active={"accueil"}/>
                        </div>

                        :  <div><section
                            style={{
                                backgroundColor: '#000',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                width: '100%',
                                height: 'calc(100vh - 70px)',
                                display: 'block',
                                overflowY: 'scroll'
                            }}
                        >
                            <div className={"flex flex-col justify-start items-center"}>
                                <div className={"flex flex-row justify-center items-center"}>
                                    <LangFront langage={langage} />
                                </div>

                            {guideBook.logoId !== null ? <img   src={global.config.uriAPI+'/'+guideBook.logo.url}
                                                                className={"w-1/4 mt-10"} /> : null}

                            <div  className={"mt-10 text-white text-4xl   " +
                                "font-bold first-letter:uppercase text-center w-4/5 sm:w-1/2"} style={{fontFamily: guideBook.settings.mainFont}}>{guideBook.name}</div>
                            </div>

                        </section><NBar styles={styles} guideBook={guideBook}  active={"accueil"}/></div>}

                </div>
            )
                : <img src={LogoAnime}
                       style={{
                           width: 80,
                           $height: 80
                       }}
                       className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}/>}</div>

    )
}