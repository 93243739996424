import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowAltCircleDown, faChevronLeft,
    faCircleExclamation,
    faCircleInfo,
    faHome,
    faLocationDot,
    faSearch
} from "@fortawesome/free-solid-svg-icons";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {Navigate} from "react-router-dom";
import Meta from "../../component/Meta";
import LangFront from "./langFront";
import { Avatar } from 'flowbite-react';
import PowerBy from "./powerby";
import LogoAnime from "../../asset/img/logoanime.gif";
import NBar from "./nbar";

function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

function hexToRGB(hex, alpha) {
    console.log(hex);
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export default function Guide(props) {
    let { uuid } = useParams();
    const [categorys, setCategorys] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const { t } = useTranslation();
    const [guideBook, setGuideBook] = useState([]);
    const [langage, setLangage] = useState([]);
    const langId = localStorage.getItem('langId');
    const [styles, setStyles] = useState({overlay: '', item: '', itemActive: '', navigationBar: ''});
    const [meta, setMeta] = useState({title: '', font: '', description: '', image: ''});

    const [hoveredItems, setHoveredItems] = useState([]);

    const handleHover = (index) => {
        return () => {
            setHoveredItems(previousState => {
                return {...previousState, [index]: !previousState[index]}
            })
        }
    };

    useEffect(() => {

        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI+'/guidebook/'+uuid, )
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data);

                const updateStyles = {
                    overlay : {
                        position: 'relative'
                    },
                    item: {
                        backgroundColor: data.settings.navigationBarColor,
                    },
                    header: {
                        backgroundColor: data.settings.navigationBarColor,
                        height: '90px'
                    },
                    itemHover:{
                        backgroundColor: adjust(data.settings.navigationBarColor, -40)
                    },
                    itemActive: {
                        backgroundColor: adjust(data.settings.navigationBarColor, -40)
                    },
                    navigationBar: {
                        color: '#fff',
                        fontFamily: data.settings.mainFont,
                        fontSize: '0.7rem',
                        height: '70px'
                    },
                    borderItem: {
                        borderBottom: '1px solid '+data.settings.navigationBarColor,
                    },
                    fontText: {
                        fontFamily: data.settings.mainFont,
                    },
                    category: {}
                }

                const meta = {
                    title: data.name,
                    font: data.settings.mainFont,
                    description: data.name,
                    image: global.config.uriAPI+'/'+data.media.url
                }
                setMeta(meta);
                /*updateStyles.item[':hover']= {
                    backgroundColor: adjust(data.settings.navigationBarColor, -40)
                }*/
                setStyles(updateStyles);

                fetch(global.config.uriAPI+'/guidebook/category/'+uuid+"/"+langId, )
                    .then((response) => response.json())
                    .then((data) => {
                        setCategorys(data);
                        setLoadData(true);
                    })
                    .catch((err) => {
                    });
            })
            .catch((err) => {
            });
    }, [langId]);

    return (
        <div className={""}>
            {loadData ? <div style={styles.overlay} >
                <Meta meta={meta} />
                    <header className={"flex flex-col top-0 justify-around items-center z-1   w-full"}
                         style={styles.header}
                    >
                        <div className={"flex flex-row gap-5 flex-wrap justify-around items-center z-1   w-full"}>
                            {guideBook.logoId !== null ? <img   src={global.config.uriAPI+'/'+guideBook.logo.url}
                                                                style={{
                                                                    width: 40
                                                                }}
                                                                className={""} /> : null}
                            <div className={"flex flex-row justify-start items-center"}>
                                <a href={"/guidebook/"+uuid} className={" rounded w-fit pr-2 pl-2"} style={{
                                    color: guideBook.settings.iconPrimaryColor,
                                    backgroundColor: guideBook.settings.navigationBarColor
                                }}><FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon></a>
                                <LangFront langage={langage} />
                            </div>
                        </div>
                        <div className={"text-white font-bold p-0.5 text-sm first-letter:uppercase"}>
                            {guideBook.name}
                        </div>
                    </header>
                    <section className={"h-full"} style={{
                        backgroundImage: `linear-gradient(
                  ${hexToRGB(guideBook.settings.mediaOverlay, guideBook.settings.imageOverlayPercentage / 100)},
                ${hexToRGB(guideBook.settings.mediaOverlay, guideBook.settings.imageOverlayPercentage / 100)}),
                url(${global.config.uriAPI+'/'+guideBook.media.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: 'calc(100vh - 161px)',
                        display: 'block',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingRight:'15px',
                        paddingLeft: '15px',
                        overflowY: 'scroll'
                    }}
                    >

                        <div
                            style={{

                            }}
                            className={"   "
                        }>
                            <div className={"flex flex-col gap-3 mb-20 h-full"} >
                            {categorys.map((category, index, array) =>
                                <a href={"/guidebook/subcategory/"+category.uuid+"/"+guideBook.uuid}

                                   className={"bg-white flex flex-row rounded  shadow-md  hover:bg-gray-200 items-center font-bold pl-2 pt-0.5 pb-0.5 "}
                                >
                                    {category.media != null  ?
                                        <Avatar img={global.config.uriAPI+'/'+category.media.url} size="xs" />
                                        : null}
                                    <div  style={styles.fontText} className={"first-letter:uppercase p-4 text-base"}>{category.name}</div>
                                </a>
                            )}
                            </div>
                        </div>

                    </section>
                    <NBar styles={styles} guideBook={guideBook} active={"guide"}/>
                </div>
                :
                <img src={LogoAnime}
                     style={{
                         width: 80,
                         $height: 80
                     }}
                     className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}/>
            }

        </div>
    )
}