import React,{Component}  from 'react';
import zxcvbn from 'zxcvbn';

import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTriangleExclamation, faFileShield} from "@fortawesome/free-solid-svg-icons";

function PasswordStrengthMeter (props) {
    const password = props.password;
    const score = zxcvbn(password).score;
    const { t } = useTranslation();

    if (password != '') {
        switch (score) {
            case 0:
                return (
                    <div className="password-strength-meter" class="bg-amber-600 p-2 text-xs text-white rounded m-1">
                        <FontAwesomeIcon icon={faTriangleExclamation} /> {t('Mot de passe trop faible')}
                    </div>
                )
            case 1:
                return (
                    <div className="password-strength-meter" class="bg-amber-600 p-2 text-xs text-white rounded m-1">
                        <FontAwesomeIcon icon={faTriangleExclamation} /> {t('Mot de passe trop faible')}
                    </div>
                )
            case 2:
                return (
                    <div className="password-strength-meter" class="bg-blue-400 p-2 text-xs text-white rounded m-1">
                        <FontAwesomeIcon icon={faTriangleExclamation} /> {t('Mot de passe pas assez fort')}
                    </div>
                )
            case 3:
                return (
                    <div className="password-strength-meter" class="bg-green-600 p-2 text-xs text-white rounded m-1">
                        <FontAwesomeIcon icon={faFileShield} /> {t('Mot de passe fort')}
                    </div>
                )
            case 4:
                return (
                    <div className="password-strength-meter" class="bg-blue-600 p-2 text-xs text-white rounded m-1">
                        <FontAwesomeIcon icon={faFileShield} /> {t('Mot de passe fort')}
                    </div>
                )
        }
    }

}

export default PasswordStrengthMeter;