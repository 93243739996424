import Error from "../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSquarePlus,
    faLink,
    faTrash,
    faEdit,
    faSpinner,
    faCog,
    faCircleExclamation, faCheck, faQrcode, faDownload
} from "@fortawesome/free-solid-svg-icons";
import Menu from './menu'
import {Button, Footer, Modal} from 'flowbite-react';
import { Card } from 'flowbite-react';
import QRCode from "react-qr-code";
import { Pagination } from 'flowbite-react';
import {useForm} from "react-hook-form";
import { Toast } from 'flowbite-react';
import i18n from "../../component/i18n";
import { Accordion } from 'flowbite-react';
import {useLocation} from "react-router-dom";

export default function Aide() {

    const { t } = useTranslation();
    const lng = i18n.language;
    const [article, setArticle] = useState([]);
    const [loading, setLoading] = useState(false);

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    //const [searchParams] = useSearchParams();
    //console.log(searchParams);
    const lang = params.get("lang");

    if (lang != undefined) {

    }

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+global.config.strapi)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriStrapi+'/blogokurensio-frs?locale='+lng+'&populate=*', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setArticle(data.data);
                setLoading(true)
            })
            .catch((err) => {
            });


    }, [lng]);

    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <div className="w-full">
                <Menu/>
            </div>
            <div className=" sm:container flex flex-row justify-center mx-auto container ">
                <a href={"https://okurensio.freshdesk.com/support/home"} target={"_blank"} className={"text-center"}>{t('Ouvrir un ticket')}</a><br/>
                {loading ?
                    <Accordion>
                        {article.map((item, index) =>
                            <Accordion.Panel>
                                <Accordion.Title className={"first-letter:uppercase"}>{item.attributes.title}</Accordion.Title>
                                <Accordion.Content>
                                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                                        <div dangerouslySetInnerHTML={{__html: item.attributes.pck}}></div>
                                    </p>
                                </Accordion.Content>
                            </Accordion.Panel>
                        )}
                    </Accordion>
                    : <FontAwesomeIcon icon={faSpinner} spin={true} />}


            </div>
            <Lang />
        </section>
    )
}