import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import PlaceholderImg from '../../asset/img/placeholder.png';
import LocationImg from '../../asset/img/location.png';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowAltCircleDown, faArrowLeft, faChevronLeft,
    faCircleExclamation,
    faCircleInfo,
    faHome,
    faLocationDot,
    faSearch,
    faPercent,
    faTag
} from "@fortawesome/free-solid-svg-icons";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {Navigate} from "react-router-dom";
import Meta from "../../component/Meta";
import LangFront from "./langFront";
import {Avatar, Button, Modal} from 'flowbite-react';
import LogoAnime from "../../asset/img/logoanime.gif";
import NBar from "./nbar";
import GoogleMapReact from 'google-map-react';


function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

function hexToRGB(hex, alpha) {
    console.log(hex);
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export default function DiscountCodeFront(props) {
    let { categoryUuid , subCategoryUuid, uuid } = useParams();
    const [topics, setTopics] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const { t } = useTranslation();
    const [guideBook, setGuideBook] = useState([]);
    const [langage, setLangage] = useState([]);
    const langId = localStorage.getItem('langId');
    const [styles, setStyles] = useState({overlay: '', item: '', itemActive: '', navigationBar: ''});
    const [meta, setMeta] = useState({title: '', font: '', description: '', image: ''});
    const [openModal, setOpenModal] = useState(false);
    const [hoveredItems, setHoveredItems] = useState([]);
    const [topicShow, setTopicShow] = useState([]);


    useEffect(() => {

        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI+'/guidebook/'+uuid, )
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data);

                const updateStyles = {
                    overlay : {
                        position: 'relative'
                    },
                    item: {
                        backgroundColor: data.settings.navigationBarColor,
                    },
                    itemHover:{
                        backgroundColor: adjust(data.settings.navigationBarColor, -40)
                    },
                    itemActive: {
                        backgroundColor: adjust(data.settings.navigationBarColor, -40)
                    },
                    colorTitle: {
                        color: adjust(data.settings.navigationBarColor, -40),
                    },
                    header: {
                        backgroundColor: data.settings.navigationBarColor,
                        height: '90px'
                    },
                    navigationBar: {
                        color: '#fff',
                        fontFamily: data.settings.mainFont,
                        fontSize: '0.7rem',
                        height: '70px'
                    },
                    borderItem: {
                        borderBottom: '1px solid '+data.settings.navigationBarColor,
                    },
                    fontText: {
                        fontFamily: data.settings.mainFont,
                    },
                    category: {},
                    topicDistance: {
                        backgroundColor: data.settings.navigationBarColor
                    },
                    textTopic: {
                        fontFamily: data.settings.mainFont,
                        color: data.settings.navigationBarColor
                    }
                }

                const meta = {
                    title: data.name,
                    font: data.settings.mainFont,
                    description: data.name,
                    image: global.config.uriAPI+'/'+data.media.url
                }
                setMeta(meta);
                /*updateStyles.item[':hover']= {
                    backgroundColor: adjust(data.settings.navigationBarColor, -40)
                }*/
                setStyles(updateStyles);

                fetch(global.config.uriAPI+'/guidebook/discountcode/'+uuid+"/"+langId, )
                    .then((response) => response.json())
                    .then((data) => {
                        setTopics(data);
                        setLoadData(true);
                    })
                    .catch((err) => {
                    });
            })
            .catch((err) => {
            });
    }, [langId]);

    const handleHover = (index) => {
        return () => {
            setHoveredItems(previousState => {
                return {...previousState, [index]: !previousState[index]}
            })
        }
    };


    const Placeholder = ({ text }) => <img src={PlaceholderImg} style={{ width:32, height: 'auto'}} />;
    const Location = ({ text }) => <img src={LocationImg} style={{ width:32, height: 'auto'}} />;
    const updateModal = (uuid) => {
        return (e) => {
            e.preventDefault();
            fetch(global.config.uriAPI+'/guidebook/topicshow/'+uuid+"/"+langId, )
                .then((response) => response.json())
                .then((data) => {
                    setTopicShow(data);
                    // document.getElementById("modal").innerHTML = data.content;
                    setOpenModal(true);
                })
                .catch((err) => {
                });
        }
    }



    return (
        <div className={""}>
            {loadData ? <div style={styles.overlay} >
                    <Meta meta={meta} />
                    <header className={"flex flex-col top-0 justify-around items-center z-1   w-full"}
                            style={styles.header}
                    >
                        <div className={"flex flex-row gap-5 flex-wrap justify-around items-center z-1   w-full"}>
                            {guideBook.logoId !== null ? <img   src={global.config.uriAPI+'/'+guideBook.logo.url}
                                                                style={{
                                                                    width: 40
                                                                }}
                                                                className={""} /> : null}
                            <div className={"flex flex-row justify-start items-center"}>
                                <a href={"/guidebook/subcategory/"+categoryUuid+'/'+uuid} className={" rounded w-fit pr-2 pl-2"} style={{
                                    color: guideBook.settings.iconPrimaryColor,
                                    backgroundColor: guideBook.settings.navigationBarColor
                                }}><FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon></a>
                                <LangFront langage={langage} />
                            </div>
                        </div>
                        <div className={"text-white font-bold p-0.5 text-sm first-letter:uppercase"}>
                            {guideBook.name}
                        </div>
                    </header>
                    <section style={{
                        backgroundImage: `linear-gradient(
                  ${hexToRGB(guideBook.settings.mediaOverlay, guideBook.settings.imageOverlayPercentage / 100)},
                ${hexToRGB(guideBook.settings.mediaOverlay, guideBook.settings.imageOverlayPercentage / 100)}),
                url(${global.config.uriAPI+'/'+guideBook.media.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        display: 'block',
                        height: 'calc(100vh - 161px)',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingRight:'15px',
                        paddingLeft: '15px',
                        overflowY: 'scroll'
                    }}
                    >
                        <div
                            style={{

                            }}
                            className={" "

                            }>
                            <div className={"flex flex-col gap-3 overflow-auto h-full w-full mb-20"}>

                                {topics.map((topic, index, array) =>    (
                                        topics.length ===1 ?  <div className={"bg-white  p-2 rounded shadow hover:bg-gray-200 items-center"}>
                                                <b className={"first-letter:uppercase font-bold"} style={styles.colorTitle}>{topic.topic.name} <FontAwesomeIcon icon={faTag}/> </b>

                                                <div className={"flex flex-col gap-5"}>
                                                    {topic.topic.type === 'google_place' ?
                                                        <>
                                                            <div style={styles.topicDistance} className={"p-1 text-white rounded text-xs w-fit m-2 "}>
                                                                {topic.topic.type === "google_place" ? topic.topic.distance > 1000 ? topic.topic.distance/1000 + ' km' : topic.topic.distance + 'm'  : null}
                                                            </div>
                                                            {topic.topic.media != null  ?
                                                                <img src={new RegExp("places.google", 'g').test(topic.topic.media.url) ? topic.topic.media.url : global.config.uriAPI + '/' + topic.topic.media.url} className={"w-full h-auto rounded"} />
                                                                : null}
                                                        </>
                                                        : null }


                                                    <div dangerouslySetInnerHTML={{__html: topic.topic.content}} className={"mt-5"}></div>

                                                    <div>{topic.topic.address !== '' ? topic.topic.address: null}</div>
                                                    <div>{topic.topic.phoneNumber !== '' ? topic.topic.phoneNumber: null}</div>
                                                    <div>{topic.topic.uri !== '' ?
                                                        <a href={topic.topic.uri} target={"_blank"} style={styles.textTopic} className={""}>{topic.topic.uri}</a>
                                                        : null}</div>

                                                    <div className={"bg-green-400 p-1 text-white rounded mt-1"}>
                                                        <div dangerouslySetInnerHTML={{__html: topic.name}} className={""}></div>
                                                        {topic.mediaId != null ?
                                                            <img src={global.config.uriAPI+'/'+topic.media.url} className={"w-fit mt-5"} />
                                                            : null}
                                                    </div>

                                                    {topic.topic.type === 'google_place' ?
                                                        <div style={{width:"100%",height:"250px", borderRadius: "10px;"}}>
                                                            <GoogleMapReact
                                                                bootstrapURLKeys={{ key: global.config.apiKeyGoogle }}
                                                                center={[topic.topic.latitude, topic.topic.longitude]} zoom={11}
                                                            >
                                                                <Placeholder
                                                                    lat={topic.topic.latitude}
                                                                    lng={topic.topic.longitude}
                                                                    text="My Marker"
                                                                />
                                                                <Location
                                                                    lat={guideBook.latitude}
                                                                    lng={guideBook.longitude}
                                                                    text="My Marker"
                                                                />
                                                            </GoogleMapReact>
                                                        </div>
                                                        : null }

                                                </div>
                                            </div>:
                                            <a href={"#"} onClick={updateModal(topic.topic.uuid)} className={"bg-white flex flex-row p-2 rounded shadow hover:bg-gray-200 items-center"}>
                                                {topic.topic.media !=null ?  <Avatar img={new RegExp("places.google", 'g').test(topic.topic.media.url) ? topic.topic.media.url : global.config.uriAPI + '/' + topic.topic.media.url} size="lg"/>: null }
                                                <div  style={styles.fontText} className={"first-letter:uppercase p-5 flex flex-col font-bold" }>
                                                    {topic.topic.name}
                                                    <div className={"w-fit rounded bg-green-400 p-1 text-xs text-white" }>
                                                        <FontAwesomeIcon icon={faPercent}/> {t('code de réduction disponible')}
                                                    </div>
                                                    {topic.topic.distance !== undefined ?
                                                        <div  style={styles.topicDistance} className={"p-1 text-white rounded text-xs w-fit mt-2"}>
                                                            {topic.topic.type === "google_place" ? topic.topic.distance > 1000 ? topic.topic.distance/1000 + ' km' : topic.topic.distance + 'm'  : null}
                                                        </div>
                                                        : null}
                                                </div>
                                            </a>

                                    )

                                )}
                            </div>
                        </div>
                    </section>
                    <NBar styles={styles} guideBook={guideBook} active={"code"}/>
                    <Modal show={openModal} onClose={() => setOpenModal(false)}>
                        <Modal.Header>{openModal ?
                            <span className={"font-bold first-letter:uppercase"}>{topicShow.name}</span>
                            : null}</Modal.Header>
                        <Modal.Body>
                            <div className="space-y-6" id={"modal"}>
                                {openModal ?
                                    <div className={"flex flex-col gap-5"}>
                                        {topicShow.type === 'google_place' ?
                                            <div style={styles.topicDistance} className={"p-1 text-white rounded text-xs w-fit m-2 "}>
                                                {topicShow.type === "google_place" ? topicShow.distance > 1000 ? topicShow.distance/1000 + ' km' : topicShow.distance + 'm'  : null}
                                            </div>
                                            : null }

                                        {topicShow.media != null  ?
                                            <img src={new RegExp("places.google", 'g').test(topicShow.media.url) ? topicShow.media.url : global.config.uriAPI + '/' + topicShow.media.url} className={"w-full h-auto rounded"} />
                                            : null}
                                        <div dangerouslySetInnerHTML={{__html: topicShow.content}} className={"mt-5"}></div>

                                        <div>{topicShow.address !== '' ? topicShow.address: null}</div>
                                        <div>{topicShow.phoneNumber !== '' ? <a href={"tel:"+topicShow.phoneNumber} className={"underline"}>{topicShow.phoneNumber} </a>: null}</div>
                                        <div>{topicShow.uri !== '' ?
                                            <a href={topicShow.uri} target={"_blank"} style={styles.textTopic} className={"underline"}>{topicShow.uri}</a>
                                            : null}</div>
                                        {topicShow.hasDiscountCode === true ?
                                            <div className={"bg-green-400 p-1 text-white rounded"}>
                                                <div dangerouslySetInnerHTML={{__html: topicShow.discountCode.name}} className={""}></div>
                                                {topicShow.discountCode.mediaId != null ?
                                                    <img src={global.config.uriAPI+'/'+topicShow.discountCode.media.url} className={"w-fit mt-5"} />
                                                    : null}
                                            </div>
                                            : null }

                                        {topicShow.type === 'google_place' ?
                                            <div style={{width:"100%",height:"250px", borderRadius: "10px;"}}>
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{ key: global.config.apiKeyGoogle }}
                                                    center={[topicShow.latitude, topicShow.longitude]} zoom={11}
                                                >
                                                    <Placeholder
                                                        lat={topicShow.latitude}
                                                        lng={topicShow.longitude}
                                                        text="My Marker"
                                                    />
                                                    <Location
                                                        lat={guideBook.latitude}
                                                        lng={guideBook.longitude}
                                                        text="My Marker"
                                                    />
                                                </GoogleMapReact>
                                            </div>
                                            : null }

                                    </div>
                                    : null}
                            </div>
                        </Modal.Body>

                    </Modal>
                </div>
                :
                <img src={LogoAnime}
                     style={{
                         width: 80,
                         $height: 80
                     }}
                     className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}/>
            }

        </div>
    )
}