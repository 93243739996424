import Logo from '../asset/img/okurensiologo.svg';
import Loginimg from '../asset/img/loginimg.png';
import { Form } from "react-router-dom";
import Error from "../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { Navbar } from 'flowbite-react';
import Lang from '../component/lang.jsx'
import  Meta  from "../component/Meta";


import Img1 from '../asset/img/architecture-2260836_640.jpg';
import Img2 from '../asset/img/hot-air-balloons-4561267_640.jpg';
import Img3 from '../asset/img/language-2345801_640.jpg';
import Img4 from '../asset/img/qrcode.jpg';
import Demo1 from '../asset/img/demo1.png';
import Demo2 from '../asset/img/demo2.png';
import Visit from '../asset/img/visit.png';
import Coeur from '../asset/img/coeur.png';
import CookieConsent from "react-cookie-consent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faCheck,
    faCircle,
    faCircleArrowRight,
    faInfo,
    faQuestion,
    faTree
} from "@fortawesome/free-solid-svg-icons";
import mixpanel from "mixpanel-browser/src/loader-module";

export default function Tarif() {


    const { t } = useTranslation();
    mixpanel.init(global.config.mixpanel, {debug: true, track_pageview: true, persistence: 'localStorage'});
    const font = {
        fontFamily: "'Noto Sans', sans-serif",
    }

    const meta = {
        title: t('Créer un un livret de bienvenue numérique pour vos locations saisonnières | tarifs'),
        description: t('Créer un un livret de bienvenue numérique pour vos locations saisonnières | tarifs' ),
        image: Demo1,
        font: "Noto+Sans:ital,wght@0,300;0,900;1,400"
    }

    useEffect(() => {
        var sc = document.createElement("script");
        sc.setAttribute("defer",true);
        sc.setAttribute("src","https://dbwx2z9xa7qt9.cloudfront.net/bundle.js?cachebust=1706685231023");
        sc.setAttribute("theme","light");
        sc.setAttribute("footer", "true");
        sc.setAttribute("widget-type","carousel");
        sc.setAttribute("token","65b892009e2a72f323300085");
        sc.setAttribute('apiurl', "https://server.onlinereviews.tech/api/v0.0.9");
        sc.setAttribute('stats',"true");
        sc.setAttribute('addReview',"true");
        sc.setAttribute('profile-pic',"true");
        sc.setAttribute('review-name',"true");
        sc.setAttribute('wl', "true");
        sc.setAttribute('wlndig', "https://go.gestion-avis.fr/okurensio");
        sc.setAttribute('lang', "us");
        sc.setAttribute('brandStyle', "%7B%22sidebar_background%22%3A%22%23004AAD%22%2C%22sidebar_text%22%3A%22white%22%2C%22brand_button_text_color%22%3A%22white%22%2C%22brand_main_color%22%3A%22%23000000%22%2C%22brand_button_border_radius%22%3A%2220px%22%2C%22brand_button_hover%22%3A%22rgb(0%2C%200%2C%200)%22%2C%22brand_button_active%22%3A%22rgb(0%2C%200%2C%200)%22%2C%22brand_main_color_opacity%22%3A%22%230000001a%22%2C%22brand_font%22%3A%22Montserrat%22%2C%22star_color%22%3A%22%23128c7e%22%2C%22brand_main_background%22%3A%22%23F6F8F7%22%2C%22brand_card_background%22%3A%22white%22%2C%22poweredByLink%22%3A%22https%3A%2F%2Fgestion-avis.fr%22%2C%22poweredicon%22%3A%22https%3A%2F%2Frecensioni-io-static-folder.s3.eu-central-1.amazonaws.com%2Fpublic_onlinereviews%2Fapp.gestion-avis.fr%2Ftopbar.png%22%7D");
        document.getElementById("wid_1706685231023").appendChild(sc);

    }, []);

    return (
        <section  className="bg-hray-100 min-h-screen items-center justify-center flex flex-col ">
            <Meta meta={meta} />
            <div
                style = {{
                    fontWeight: 800
                }}
                className={"text-center p-5 w-full bg-cyan-700 text-white text-2xl"}>
                {t('7 jours d\'essai gratuit')} <a href={"/register"}><FontAwesomeIcon icon={faCircleArrowRight} /></a>
            </div>
            <div className={"flex justify-end gap-5 items-end w-full mt-10 mr-20"}>
                <a href={"/"} className={"hover:bg-cyan-700 text-cyan-700 hover:rounded  hover:text-white p-2 text-xs"}>{t('Accueil')}</a>
                <a href={"/login"} className={"hover:bg-cyan-700 text-cyan-700 hover:rounded  hover:text-white p-2 text-xs"}>{t('Me connecter')}</a>
                <a href={"/tarif"} className={"hover:bg-cyan-700 text-cyan-700 hover:rounded  hover:text-white p-2 text-xs"}>{t('Tarifs')}</a>
                <a href={"/articles"} className={"hover:bg-cyan-700 text-cyan-700 hover:rounded hover:text-white p-2 text-xs"}>{t('Articles')}</a>
                <Lang />
            </div>
            <div
                className="flex rounded-2xl  p-5 items-center flex-col container mt-10 mb-10">
                <div className={"bold text-xl font-bold mb-10"}>
                    {t('Tarif pour la creation de vos livrets')}
                </div>
                <div className={"flex gap-5 flex-row"}>
                    <div className={"bg-gray-300 rounded p-5 bold"}>
                        <div className={"flex text-xl font-bold justify-center items-center mb-5"}>{t('30 € TTC par an (moins de 3 € TTC par mois) ')}</div>
                        <ul>
                            <li>- {t('2 livrets maximun')}</li>
                            <li>- {t('Accès à toute les fonctionnalités')}</li>
                        </ul>
                    </div>
                    <div className={"bg-gray-200 rounded p-5 bold"}>
                        <div className={"flex text-xl font-bold justify-center items-center mb-5"}>{t('199 € HT par an (moins de 20 € ht par mois)')}</div>
                        <ul>
                            <li>- {t('livrets illimité')}</li>
                            <li>- {t('Accès à toute les fonctionnalités')}</li>
                        </ul>

                    </div>
                </div>
                <div className={"flex justify-center items-center"}>
                    <a href={"/register"} className={"bg-cyan-700 rounded p-2 text-lg font-bold text-white mt-5"}>{t('Créer un livret')}</a>
                </div>

                <div id="wid_1706685231023"></div>
            </div>
            <CookieConsent
                location="bottom"
                buttonText={t('Accepter')}
                cookieName="okurensio"
                style={{ background: "#2B373B" }}
                buttonStyle={{ background: "#0097B2" , color: "#ffffff", fontSize: "13px" }}
                expires={150}
            >
                {t('Nous utilitons des cookies pour améliorer votre expérience d\'utilisation')}.{" "}
                <span style={{ fontSize: "10px" }}></span>
            </CookieConsent>
        </section>
    );
}