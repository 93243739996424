import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSquarePlus,
    faLink,
    faTrash,
    faEdit,
    faSpinner,
    faCog,
    faCircleExclamation, faCheck, faArrowRightToBracket, faHome, faMap, faSearch, faCircleInfo, faMapPin, faLocationDot
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {Button, Footer, Modal} from 'flowbite-react';
import { Card } from 'flowbite-react';
import QRCode from "react-qr-code";
import { Pagination } from 'flowbite-react';
import {useForm} from "react-hook-form";
import { Toast } from 'flowbite-react';
import {Form, useParams} from "react-router-dom";
import { FileInput, Label } from 'flowbite-react';
import FontPicker from 'react-fontpicker-ts'
import 'react-fontpicker-ts/dist/index.css'
import { TwitterPicker } from 'react-color'
import  Meta  from "../../../component/Meta";
import { Breadcrumb } from 'flowbite-react';


function hexToRGB(hex, alpha) {
       console.log(hex);
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export default function SettingGuidebook(props) {

    const MAX_FILE_SIZE = 5120 // 5MB

    let { uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const { t } = useTranslation();
    const [inputs, setInputs] =  useState({mainFont :  '', alternativeFont : 'open sans', imageOverlay: '', imageOverlayPercentage : '',
        navigationBarColor: '#333', iconPrimaryColor: '#fff', media: '', name: '', logo: ''});
    const [button, setButton] = useState({disabled : '', loader: ''});
    const [displayColorPickerNBC, setDisplayColorPickerNBC] = useState(false);
    const [displayColorPickerIPC, setDisplayColorPickerIPC] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [linkPDF, setLinkPDF] = useState('');

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }

    const onSubmit = (data) => {

        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        //myHeaders.append('Content-Type', 'Multipart/form-data');

        let formData = new FormData()
        let fileData = document.querySelector('#image').files[0];
        if (fileData) {
        const fileSizeKiloBytes = fileData.size / 1024
            const extension = fileData.type;

            if (['image/png', 'image/jpg', 'image/jpeg', 'image/heic'].includes(extension) === false) {
                setError('media', { type: 'error', message: t('Le fichier doit être au format png, heic, jpg ou jpeg') });
                return;
            }

            if(fileSizeKiloBytes > MAX_FILE_SIZE){
                setError('media', { type: 'size', message: t('Le fichier est trop volumineux') });
                return
            }
        }

        let fileLogo = document.querySelector('#logo').files[0];
        if (fileLogo) {
            const fileSizeKiloBytesLogo = fileLogo.size / 1024
            const extensionLogo = fileLogo.type;

            if (['image/png', 'image/jpg', 'image/jpeg', 'image/heic'].includes(extensionLogo) === false) {
                setError('logo', { type: 'error', message: t('Le fichier doit être au format png, heic, jpg ou jpeg') });
                return;
            }

            if(fileSizeKiloBytesLogo > MAX_FILE_SIZE){
                setError('logo', { type: 'size', message: t('Le fichier est trop volumineux') });
                return
            }
        }

        setLoadData(false);

        formData.append('image', fileData);
        formData.append('logo', fileLogo);
        formData.append('mainFont', inputs.mainFont);
        formData.append('alternativeFont', 'open sans');
        formData.append('mediaOverlay', inputs.imageOverlay);
        formData.append('imageOverlayPercentage', inputs.imageOverlayPercentage);
        formData.append('navigationBarColor', inputs.navigationBarColor);
        formData.append('iconPrimaryColor', inputs.iconPrimaryColor);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/settingsbook/update/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setInputs({
                    mainFont : data.mainFont,
                    alternativeFont : data.alternativeFont,
                    imageOverlay: data.mediaOverlay,
                    imageOverlayPercentage : data.imageOverlayPercentage,
                    navigationBarColor: data.navigationBarColor,
                    iconPrimaryColor: data.iconPrimaryColor,
                    media: data.media,
                    name: data.name,
                    logo: data.logo
                });
                setLoadData(true);

                if (data.media.url !== undefined)
                {
                    const myHeaders = new Headers();

                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append('Authorization', 'Bearer ' + token)

                    var requestOptions = {
                        method: 'GET',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(global.config.uriAPI + '/app/guidebook/pdf/'+uuid, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            setLinkPDF(data.url);
                        })
                        .catch((err) => {
                        });
                }
            })
            .catch((err) => {
            });
    }

    const validExtension = () => {
        let fileData = document.querySelector('#image').files[0];
        const extension = fileData.type;

        if (['image/png', 'image/jpg', 'image/jpeg', 'image/heic'].includes(extension) === false) {
            setError('media', { type: 'error', message: t('Le fichier doit être au format png, jpg, heic ou jpeg') });
            return
        }
        setError('media', null);
    }

    const validLogo = () => {
        let fileData = document.querySelector('#logo').files[0];
        const extension = fileData.type;
        if (['image/png', 'image/jpg', 'image/jpeg', 'image/heic'].includes(extension) === false) {
            setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg, heic ou jpeg') });
            return
        }
        setError('logo', null);
    }


    const deleteImg = (event) =>
    {
        const myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token)

        const mediaId = event.target.getAttribute('data-mediaId');
        const type = event.target.getAttribute('data-type');

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/settingsbook/deleteimg/'+uuid+'/'+mediaId+'/'+type, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setLoadData(false);
            })
            .catch((err) => {
            });
    }

    useEffect(() => {
        const myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/settingsbook/show/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setInputs({
                    mainFont : data.mainFont,
                    alternativeFont : data.alternativeFont,
                    imageOverlay: data.mediaOverlay,
                    imageOverlayPercentage : data.imageOverlayPercentage,
                    navigationBarColor: data.navigationBarColor,
                    iconPrimaryColor: data.iconPrimaryColor,
                    media: data.media,
                    name: data.name,
                    logo: data.logo
                });
                setLoadData(true);

                if (data.media.url !== undefined)
                {
                    const myHeaders = new Headers();

                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append('Authorization', 'Bearer ' + token)

                    var requestOptions = {
                        method: 'GET',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    fetch(global.config.uriAPI + '/app/guidebook/pdf/'+uuid, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            setLinkPDF(data.url);
                        })
                        .catch((err) => {
                        });
                }
            })
            .catch((err) => {
            });

    },[]);

    const handleClickNBC = () => {
            setDisplayColorPickerNBC(!displayColorPickerNBC);
    };

    const handleCloseNBC = () => {
        setDisplayColorPickerNBC(!displayColorPickerNBC);
    };

    const handleChangeColorNBC = (color) => {
        setInputs({ ...inputs, ["navigationBarColor"]: color.hex});
        //setData({ ...data, [e.target.name]: e.target.value });
        setDisplayColorPickerNBC(!displayColorPickerNBC);
    };

    const stylesNavigationBarColor = {
        color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${inputs.navigationBarColor}`
        },
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer'
        },

    };

    const handleClickIPC = () => {
        setDisplayColorPickerIPC(!displayColorPickerIPC);
    };

    const handleCloseIPC= () => {
        setDisplayColorPickerIPC(!displayColorPickerIPC);
    };

    const handleChangeColorIPC= (color) => {
        setInputs({ ...inputs, ["iconPrimaryColor"]: color.hex});
        setDisplayColorPickerIPC(!displayColorPickerIPC);
    };

    const stylesIPC = {
        color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${inputs.iconPrimaryColor}`
        },
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        }
    };

    const navigationBarColorCss = {
        color: '#fff',
        fontFamily: inputs.mainFont,
        fontSize: '0.7rem'
    }
    const bgNavigationBarCss = {
        backgroundColor: inputs.navigationBarColor,
    }

    const activeNavigationCss = {
        backgroundColor: adjust(inputs.navigationBarColor, -40)
    }

    const ovrlayCss =  {
        backgroundImage: ` linear-gradient(
      ${hexToRGB(inputs.imageOverlay, inputs.imageOverlayPercentage / 100)},
      ${hexToRGB(inputs.imageOverlay, inputs.imageOverlayPercentage / 100)}
    ), url(${global.config.uriAPI+'/'+inputs.media.url})`,
        width: '100%',
        height: 400,
        display: 'block',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }

    const meta = {
        title: t('Configuration du livret'),
        font: inputs.mainFont

    }

    const buttonGetStartedCss = {
        color: inputs.iconPrimaryColor,
        fontFamily: inputs.mainFont
    }
    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <Meta meta={meta} />
            <div className="w-full">
                <Menu/>
            </div>
            <div className="sm:container flex items-center flex-col p-5">
                <Breadcrumb aria-label="Solid background breadcrumb example" className="bg-gray-50 px-5 py-3 dark:bg-gray-800">
                    <Breadcrumb.Item href="/app/home" >
                        {t('Accueil')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={"/app/guidebook/show/"+uuid}>{t('Livret')}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t('Modifier')}</Breadcrumb.Item>
                </Breadcrumb>
                <div className=" rounded p-5  w-full">
                    {loadData ?
                        <Form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate id="formCreate" className={"flex flex-col gap-5"}>
                            <div className={"bg-blue-200 text-gray-700 p-5 text-xs rounded flex flex-col gap-5"}>
                                <span><span className={"bg-blue-400 text-white p-1 rounded m-2"}>1</span> {t('Je rajoute une image de fond')}</span>
                                <span><span className={"bg-blue-400 text-white p-1 rounded m-2"}>2</span> {t('Je choisis la couleur de la barre du menu')}</span>
                                <span><span className={"bg-blue-400 text-white p-1 rounded m-2"}>3</span> {t('Je choisis la couleur du filtre')}</span>
                                <span><span className={"bg-blue-400 text-white p-1 rounded m-2"}>4</span> {t('Je configure les catégories de mon livret et les code Wifi')}
                                <a href={"/app/guidebook/show/"+uuid} className={"underline"}>{t('Configurer')}</a>
                                </span>
                            </div>
                            <b>{t('Configurer votre livret')} : <span className={"first-letter:uppercase"}>{inputs.name}</span> </b>
                            {linkPDF!== '' ?  <a href={global.config.uriAPI+'/'+linkPDF} target={"_blank"}
                                                 className="border-2 border-cyan-700 bg-cyan-500 text-center text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                                {t('Télécharger le PDF de bienvenue')}</a> :null}
                            <div id="fileUpload" className="w-full">
                                <div className="mb-2 block">
                                    <Label htmlFor="file" value={t('Télécharger un logo (non obligatoire)')} />
                                </div>
                                <FileInput id="logo"  name={"logo"}
                                           {...register("logo", { onChange: validLogo })}
                                           helperText={t('Vous pouvez mettre le logo de votre location si vous en avez un de 400 * 400 pixel par exemple')} />
                                {errors.logo &&  errors.logo.type === 'error' && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {errors.logo.message}</div>
                                )}
                                {inputs.logo.url ?
                                    <><img src={global.config.uriAPI+"/"+inputs.logo.url} className={"w-80"} />
                                        <div onClick={ deleteImg } data-mediaId={inputs.logo.id} data-type={"media"} className={"text-red-600 text-sm hover:underline m-5 cursor-pointer"}><FontAwesomeIcon icon={faTrash} /> {t('Supprimer le logo')}</div>
                                    </>
                                    : null }
                                <div className="mb-2 block mt-3">
                                    <Label htmlFor="file" value={t('Télécharger une image de fond')} />
                                </div>
                                <FileInput id="image"  name={"image"}

                                           {...register("media", { onChange: validExtension })}
                                           helperText={t('Vous pouvez mettre une image de votre location ou de votre région de 1200 * 1200 pixel par exemple')} />
                                <div className={"bg-blue-200 text-gray-700 p-5 text-xs rounded"}>
                                {t('Vous pouvez télécharger des images gratuitement sur')} <a href={"https://pixabay.com"} target={"_blank"} className={"underline"}>Pixabay</a>
                                    <br />
                                    {t('Si vous mettez aucune image, une couleur de fond par défaut sera appliqué')}
                                </div>
                                {errors.media &&  errors.media.type === 'error' && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {errors.media.message}</div>
                                )}
                                {inputs.media.url ?
                                    <p>
                                        <div style={ovrlayCss} className={"relative rounded mt-3"}>
                                            <div  style={{left:'40%',top: '10%'}} className={"absolute"}>
                                                <div style={buttonGetStartedCss} className={"p-3 rounded"}>{inputs.name}</div>
                                            </div>
                                            <div  style={navigationBarColorCss} className={"absolute  bottom-0  p-5 w-full text-center"}>
                                                <div className={"grid grid-cols-4"}>
                                                    <div style={activeNavigationCss} className={"flex flex-col gap-2 p-5 items-center"}>
                                                        <FontAwesomeIcon icon={faHome} />
                                                        {t('Accueil')}</div>
                                                    <div style={bgNavigationBarCss} className={"flex flex-col gap-2 p-5 items-center"}>
                                                        <FontAwesomeIcon icon={faCircleInfo} />
                                                        {t('Guide')}</div>
                                                    <div style={bgNavigationBarCss} className={"flex flex-col gap-2 p-5 items-center"}>
                                                        <FontAwesomeIcon icon={faLocationDot} />
                                                        {t('Carte')}</div>
                                                    <div style={bgNavigationBarCss} className={"flex flex-col gap-2 p-5 items-center"}>
                                                        <FontAwesomeIcon icon={faSearch} />
                                                        {t('Chercher')}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={ deleteImg } data-mediaId={inputs.media.id} data-type={"media"} className={"text-red-600 text-sm hover:underline m-5 cursor-pointer"}><FontAwesomeIcon icon={faTrash} /> {t('Supprimer l\'image de fond')}</div>
                                    </p>
                                    : null }
                            </div>
                            <div>
                                <Label value={t('Choisir la police principale')} />
                                <FontPicker inputId="mainFont" defaultValue={inputs.mainFont} value={(font) => {
                                    setInputs({ ...inputs, ["mainFont"]: font});
                                }  } />
                            </div>

                            <div className={"flex flex-col"}>
                                <div className={"flex gap-5 items-center "}>
                                    <Label value={t('Couleur de la barre de navigation')}  className={""}/>
                                    <div style={stylesNavigationBarColor.swatch} onClick={ handleClickNBC }>
                                        <div style={stylesNavigationBarColor.color} />
                                    </div>
                                </div>
                                { displayColorPickerNBC ?
                                    <div className={"m-5"}>
                                        <TwitterPicker
                                            color={ inputs.navigationBarColor} onChange={ handleChangeColorNBC } /></div> : null }
                            </div>
                            <div className={"flex flex-col "}>
                                <div className={"flex gap-5 items-center"}>
                                    <Label value={t('Couleur du text')}  className={""}/>
                                    <div style={stylesIPC.swatch} onClick={ handleClickIPC }>
                                        <div style={stylesIPC.color} />
                                    </div>
                                </div>
                                { displayColorPickerIPC ?
                                    <div className={"m-5"}>
                                        <TwitterPicker
                                            colors = {[ "#f44336", "#ffffff", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]}
                                            color={ inputs.iconPrimaryColor} onChange={ handleChangeColorIPC } /></div> : null }
                            </div>
                            <div className={"bg-blue-200 text-gray-700 p-5 text-xs rounded"}>
                                {t('Si le texte de la page de garde (onglet Accueil) de votre guide est difficile à lire, vous pouvez augmenter le contraste en appliquant une couleur de superposition à votre image d\'arrière-plan.')}
                                {t('Une superposition noire assombrira l\'image et comportera du texte blanc pour un contraste accru.')}
                                {t('Une superposition blanche éclaircit l\'image et comporte du texte noir pour un contraste accru.')}
                            </div>
                            <div>
                                <Label value={t('Couleur de superposition')}  className={""}/>
                                <select className={errors.imageOverlay ? "text-base mb-2 rounded px-2 py-1 w-full focus:border-red-600 text-red-700 border border-red-700":
                                    "text-base mb-2 rounded px-2 py-1 w-full"}
                                        name={"imageOverlay"}
                                        value={inputs.imageOverlay|| ""}
                                        {...register("imageOverlay", { onChange: handleChange, required: true })}
                                >
                                    <option value={""} >{t('Aucune')}</option>
                                    <option value={"#000000"} >{t('black')}</option>
                                    <option value={"#ffffff"} >{t('white')}</option>
                                </select>
                                {errors.imageOverlay && errors.imageOverlay.type === "required" && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                )}
                            </div>
                            <div>
                                <Label value={t('Transparence')}  className={""}/>
                                <input type="number" id="imageOverlayPercentage"
                                       name={"imageOverlayPercentage"}
                                       defaultValue={inputs.imageOverlayPercentage|| ""}
                                       aria-invalid={errors.imageOverlayPercentage ? "true" : "false"}
                                       {...register("imageOverlayPercentage", { onChange: handleChange, required: true })}
                                       className= { errors.imageOverlayPercentage ?
                                           "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                           "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                       }
                                       placeholder="20" max={"100"} min={"0"}/>
                                {errors.imageOverlayPercentage && errors.imageOverlayPercentage.type === "required" && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                )}
                            </div>
                            <button type="submit" disabled={button.disabled}
                                    className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                                {t('Enregistrer la configuration')}
                            </button>
                        </Form>:
                        <FontAwesomeIcon icon={faSpinner} className={"animate-spin"} />
                    }
                </div>
            </div>
        </section>
    );
}