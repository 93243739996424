import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowAltCircleDown,
    faCircleExclamation,
    faCircleInfo,
    faHome,
    faLocationDot,
    faTags,
    faSearch
} from "@fortawesome/free-solid-svg-icons";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {Navigate} from "react-router-dom";
import Meta from "../../component/Meta";
import LangFront from "./langFront";
import { Avatar } from 'flowbite-react';
import PowerBy from "./powerby";
import LogoAnime from "../../asset/img/logoanime.gif";


function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export default function NBar(props) {

    const [hoveredItems, setHoveredItems] = useState([]);
    const { t } = useTranslation();

    const handleHover = (index) => {
        return () => {
            setHoveredItems(previousState => {
                return {...previousState, [index]: !previousState[index]}
            })
        }
    };

    return (
        <div  style={props.styles.navigationBar} className={"  bottom-0 fixed w-full text-center z-50 "}>
            <div className={props.guideBook.nbDiscountCode > 0 ? "grid grid-cols-4": "grid grid-cols-3" }>
                <a
                    href={"/guidebook/"+props.guideBook.uuid}
                    className={"flex flex-col gap-2 p-5 items-center text-sm "}
                   style={{
                       ...(props.active === 'accueil' ?props.styles.itemActive: props.styles.item),
                       ...(hoveredItems['accueil'] ? props.styles.itemHover : {}),
                   }}
                    onMouseEnter={handleHover('accueil')}
                    onMouseLeave={handleHover('accueil')}
                >
                    <FontAwesomeIcon icon={faHome} />
                    {t('Accueil')}</a>
                <a
                    href={"/guidedetails/"+props.guideBook.uuid}
                    style={{
                        ...(props.active === 'guide' ?props.styles.itemActive: props.styles.item),
                        ...(hoveredItems['guide'] ? props.styles.itemHover : {}),
                    }} className={"flex flex-col gap-2 p-5 items-center text-sm item"}
                    onMouseEnter={handleHover('guide')}
                    onMouseLeave={handleHover('guide')}
                >
                    <FontAwesomeIcon icon={faCircleInfo} />
                    {t('Guide')}</a>
                {props.guideBook.nbDiscountCode > 0 ?
                    <a href={"/guidebook/discountcode/"+props.guideBook.uuid} style={{
                        ...(props.active === 'code' ?props.styles.itemActive: props.styles.item),
                        ...(hoveredItems['code'] ? props.styles.itemHover : {}),
                    }} className={"flex flex-col gap-2 p-5 items-center text-sm item"}
                       onMouseEnter={handleHover('code')}
                       onMouseLeave={handleHover('code')}
                    >
                        <FontAwesomeIcon icon={faTags} />
                        {t('Réduction')}</a>

                    : null}
                <a href={"/guidebook/carte/"+props.guideBook.uuid} style={{
                    ...(props.active === 'carte' ?props.styles.itemActive: props.styles.item),
                    ...(hoveredItems['carte'] ? props.styles.itemHover : {}),
                }} className={"flex flex-col gap-2 p-5 items-center text-sm item"}
                     onMouseEnter={handleHover('carte')}
                     onMouseLeave={handleHover('carte')}
                >
                    <FontAwesomeIcon icon={faLocationDot} />
                    {t('Carte')}</a>
            </div>
        </div>
    )

}