import Error from "../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faForward, faUser, faSpinner, faCircleExclamation, faAdd} from "@fortawesome/free-solid-svg-icons";

import {
    Avatar,
    Dropdown, FileInput, Label,
    Navbar,
} from 'flowbite-react';
import Menu from "./menu";
import {Form} from "react-router-dom";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MyCustomUploadAdapterPlugin from "../../component/PluginUploader";
import {useForm} from "react-hook-form";
import zxcvbn from "zxcvbn";

export default function Password() {

    const { t } = useTranslation();

    ///app/user/profile
    const token = localStorage.getItem('token');
    const [user, setUser] = useState([]);

    const [error, setError] = useState({typeError : '', messageError: '', iconFont : ''});
    const [inputs, setInputs] =  useState({ passwod :'', repeatPassword : '', langageId: ''});
    const [button, setButton] = useState({disabled : '', loader: ''});
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faAdd} />});
    const [langages, setLangages] = useState([]);



useEffect(() => {
    fetch(global.config.uriAPI+'/langage')
        .then((response) => response.json())
        .then((data) => {
            setLangages(data);
        })
        .catch((err) => {
        });

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', 'Bearer '+token)

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(global.config.uriAPI+'/app/user/profile', requestOptions)
        .then((response) => {
            if(response.status != 200) throw new Error(response.status);
            else return response.json();
        })
        .then((data) => {
            setUser(data);
        })
        .catch((err) => {
        });


}, []);
    const onSubmitContent = (data) => {

        const password = data.password;
        const confirmPassword = data.repeatPassword;

        if (password === "") {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append('Authorization', 'Bearer '+token)
            var raw = JSON.stringify({"langId": data.langageId});

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };


            fetch(global.config.uriAPI+'/app/user/profile/update', requestOptions)
                .then((response) => {
                    if(response.status != 200) throw new Error(response.status);
                    else return response.json();
                })
                .then((data) => {
                    window.location.href = "/app/index";
                    return;
                });

        } else {
            if (password != confirmPassword) {
                setError(previousState => {
                    return {...previousState, typeError: "danger"}
                });
                setError(previousState => {
                    return {...previousState, messageError: t('Mot de passe non identique')}
                });
            } else {

                const score = zxcvbn(password).score;

                if (score < 3) {
                    setError(previousState => {
                        return {...previousState, typeError: "danger"}
                    });
                    setError(previousState => {
                        return {...previousState, messageError: t('Mot de passe trop faible')}
                    });
                    return;
                } else {
                    const myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append('Authorization', 'Bearer '+token)
                    var raw = JSON.stringify({"langId": data.langageId, "password": data.password});

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };


                    fetch(global.config.uriAPI+'/app/user/profile/update', requestOptions)
                        .then((response) => {
                            if(response.status != 200) throw new Error(response.status);
                            else return response.json();
                        })
                        .then((data) => {
                            window.location.href = "/app/index";
                            return;
                        })
                        .catch((err) => {
                        });

                }
            }

        }


    }
    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <div className="w-full">
                <Menu/>
            </div>
            <div className="sm:container mx-auto  p-5">
                <Error typeError={error.typeError} message={error.messageError} iconFont={error.iconFont} />
                <Form action="" method="post" onSubmit={handleSubmit(onSubmitContent)} noValidate id="formCreate" className={"flex flex-col gap-5 mt-5"}>
                    <b>{t('Modifier votre mot de passe')}</b>

                    <div className="mt-3 w-full">
                        <label htmlFor="password" className={ errors.password ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Mot de passe')}</label>
                        <input type="password" id="password"
                               name ="password"
                               aria-invalid={errors.password ? "true" : "false"}
                               defaultValue={inputs.password|| ""}
                               {...register("password", { onChange: handleChange})}
                               className= { errors.password ?
                                   "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                   "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                               }
                               placeholder={t("*****")}/>
                        {errors.password && errors.password.type === "required" && (
                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                        )}
                    </div>

                    <div className="mt-3 w-full">
                        <label htmlFor="repeatPassword" className={ errors.repeatPassword ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Repeter le mot de passe')}</label>
                        <input type="password" id="repeatPassword"
                               name ="repeatPassword"
                               aria-invalid={errors.repeatPassword ? "true" : "false"}
                               defaultValue={inputs.repeatPassword|| ""}
                               {...register("repeatPassword", { onChange: handleChange})}
                               className= { errors.repeatPassword ?
                                   "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                   "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                               }
                               placeholder={t("********")}/>
                        {errors.repeatPassword && errors.repeatPassword.type === "required" && (
                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                        )}
                    </div>
                    <div className="mt-3 w-full">
                        {langages.length >0 ? (
                            <select className={errors.langageId ? "text-base mb-2 rounded px-2 py-1 w-full focus:border-red-600 text-red-700 border border-red-700":
                                "text-base mb-2 rounded px-2 py-1 w-full"
                            } id="langageId"
                                    onChange={handleChange}
                                    aria-invalid={errors.langageId ? "true" : "false"}
                                    {...register("langageId", { required: true })}
                            >
                                <option value="">{t('Choisir une langue')}</option>
                                {langages.map((langage) =>

                                    <option value={langage.id} selected={langage.id=== user.langId}><span className="uppercase">{langage.lang}</span>
                                    </option>,
                                )}
                            </select>
                        ) : (
                            <FontAwesomeIcon icon={faSpinner} />)}
                    </div>
                    <button type="submit" disabled={button.disabled}
                            className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                        {iconButton.icon}&nbsp;&nbsp; {t('Modifier')}
                    </button>
                </Form>
            </div>
        </section>
    )
}