import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import {Breadcrumb, FileInput, Label} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyCustomUploadAdapterPlugin from "../../../component/PluginUploader";
import TopicContent from "./topic/topicContent";
import TopicPlace from "./topic/topicPlace";
import TopicElectrical from "./topic/topicElectrical";
import TopicWifi from "./topic/topicWifi";


export default function AddTopic(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    let { guideBookUuid, subCategoryUuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [contentType, setContentType] = useState('');

    const { t } = useTranslation();
    const meta = {
        title: t('Ajouter une categorie')
    }


    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <Meta meta={meta} />
            <div className="w-full">
                <Menu/>
            </div>
            <div className=" sm:container mx-auto w-full">
                <Breadcrumb aria-label="Solid background breadcrumb example" className="bg-gray-50 px-5 py-3 dark:bg-gray-800">
                    <Breadcrumb.Item href="/app/index" >
                        {t('Accueil')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={"/app/guidebook/show/"+guideBookUuid}>{t('Livret')}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t('Ajouter')}</Breadcrumb.Item>
                </Breadcrumb>
                <div className={"p-10 rounded bg-gray-200 mt-10"}>
                    <label htmlFor="countries" className="block mb-2 text-sm text-gray-900 dark:text-white font-bold">
                        {t('Choissisez un type de contenu')}
                    </label>
                    <select id="countries" onChange={(e) => setContentType(e.target.value)}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value={""}>{t('Choisir une option de contenu')}</option>
                        <option value={"content"}>{t('Ajouter une description')}</option>
                        <option value={"place"}>{t('Ajouter une adresse (restaurant, zoo etc)')}</option>
                        <option value={"electrical"}>{t('Ajouter une notice d\'utilisation (éléctoménager, baignoire etc)')}</option>
                        <option value={"wifi"}>{t('Code Wifi')}</option>
                    </select>
                </div>
                <div className={"p-10 "}>
                    {contentType === '' ?
                        <></>
                        : <></> }
                    {contentType === 'content' ?
                        <TopicContent guideBookUuid={guideBookUuid} subCategoryUuid={subCategoryUuid}/>
                        : <></> }
                    {contentType === 'place' ? <TopicPlace guideBookUuid={guideBookUuid} subCategoryUuid={subCategoryUuid}/> : <></> }
                    {contentType === 'electrical' ? <TopicElectrical guideBookUuid={guideBookUuid} subCategoryUuid={subCategoryUuid}/> : <></> }
                    {contentType === 'wifi' ? <TopicWifi guideBookUuid={guideBookUuid} subCategoryUuid={subCategoryUuid}/> : <></> }
                </div>
            </div>
        </section>
    )

}