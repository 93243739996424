import * as React from "react";
//import * as ReactDOM from "react-dom/client";
import ReactDom from "react-dom";
import { createRoot } from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider, useLocation,
} from "react-router-dom";
import { hydrateRoot } from 'react-dom/client';
import "./asset/index.css";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import Login from "./routes/login"
import Register from "./routes/register"
import AdminIndex from './routes/admin/index'
import LCDTemplate from "./routes/admin/guideBook/lcdtemplate";
import  './config';
import i18n from "./component/i18n";

import { library } from '@fortawesome/fontawesome-svg-core'
import AddGuideBook from "./routes/admin/guideBook/add";
import ShowGuideBook from "./routes/admin/guideBook/show";
import TopicListing from "./routes/admin/guideBook/topicListing";
import SettingGuidebook from "./routes/admin/guideBook/setting";
import QrCode from "./routes/admin/guideBook/qrCode";
import EditGuideBook from "./routes/admin/guideBook/edit";
import AddCategory from "./routes/admin/guideBook/addCategory";
import EditCategory from "./routes/admin/guideBook/editCategory";
import AddSubCategory from "./routes/admin/guideBook/addSubCategory";
import EditSubCategory from "./routes/admin/guideBook/editSubCategory";
import AddTopic from "./routes/admin/guideBook/addTopic";
import EditTopic from "./routes/admin/guideBook/topicEdit";
import Password from "./routes/admin/password";
import GuideBookIndex from "./routes/front/guideBook";
import Guide from "./routes/front/guide";
import SubCategory from "./routes/front/subCategory";
import Topic from "./routes/front/topic";
import DiscountCodeFront from "./routes/front/discountcode";
import Carte from "./routes/front/carte";
import TopicDetail from "./routes/front/topicdetail";
import FrontPassword from "./routes/password";
import NewPassword from "./routes/newpassword";
import Aide from "./routes/admin/aide";
import Articles from "./routes/articles";
import Article from "./routes/article";
import Cgv from "./routes/cgv";
import Abonnement from "./routes/admin/guideBook/abonnement";
import {HelmetProvider} from 'react-helmet-async';
import Tarif from "./routes/tarif";

import Stats from "./routes/admin/guideBook/stats";
import DiscountCode from './routes/admin/guideBook/discountcode';
import { PostHogProvider} from 'posthog-js/react'


console.log('Console says hi!');
const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/tarif",
        element: <Tarif />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/articles",
        element: <Articles />
    },
    {
        path: "/cgv",
        element: <Cgv />
    },
    {
        path: "/article/:id/:slug",
        element: <Article />
    },
    {
        path: "/guidebook/:uuid",
        element: <GuideBookIndex />
    },
    {
        path: "/guidebook/carte/:uuid",
        element: <Carte />
    },
    {
        path: "/guidebook/discountcode/:uuid",
        element: <DiscountCodeFront />
    },
    {
        path: "/guidedetails/:uuid",
        element: <Guide />
    },
    {
        path: "/guidebook/subcategory/:categoryUuid/:uuid",
        element: <SubCategory />
    },
    {
        path: "/guidebook/topic/:categoryUuid/:subCategoryUuid/:uuid",
        element: <Topic />
    },
    {
        path: "/guidebook/topicdetail/:topicUuid/:uuid",
        element: <TopicDetail />
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/lostpassword",
        element: <FrontPassword />,
    },
    {
        path: "/newpassword/:token",
        element: <NewPassword />,
    },
    {
        path: "/app/password",
        element: <Password />,
    },
    {
        path: "/register",
        element: <Register />,
    },
    {
        path: "/app/help",
        element: <Aide />,
    },
    {
        path: "/app/home",
        element: <AdminIndex />,
    },
    {
        path: "/app/guidebook/add",
        element: <AddGuideBook />,
    },
    {
        path: "/app/guidebook/template/:uuid",
        element: <LCDTemplate />,
    },
    {
        path: "/app/guidebook/show/:uuid",
        element: <ShowGuideBook />,
    },
    {
        path: "/app/guidebook/subcategory/:uuid",
        element: <TopicListing />,
    },
    {
        path: "/app/guidebook/settings/:uuid",
        element: <SettingGuidebook />,
    },
    {
        path: "/app/guidebook/qrcode/:uuid",
        element: <QrCode />,
    },
    {
        path: "/app/guidebook/edit/:uuid",
        element: <EditGuideBook />,
    },
    {
        path: "/app/guidebook/addcategory/:uuid",
        element: <AddCategory />,
    },
    {
        path: "/app/guidebook/editcategory/:guideBookUuid/:uuid",
        element: <EditCategory />,
    },
    {
        path: "/app/guidebook/addsubcategory/:guideBookUuid/:categoryUuid",
        element: <AddSubCategory />,
    },
    {
        path: "/app/guidebook/editsubcategory/:guideBookUuid/:categoryUuid/:uuid",
        element: <EditSubCategory />,
    },
    {
        path: "/app/guidebook/addtopic/:guideBookUuid/:subCategoryUuid",
        element: <AddTopic />,
    },
    {
        path: "/app/guidebook/edittopic/:guideBookUuid/:uuid",
        element: <EditTopic />,
    },
    {
        path: "/app/guidebook/discountcodetopic/:guideBookUuid/:uuid",
        element: <DiscountCode />,
    },
    {
        path: "/app/plan",
        element: <Abonnement />,
    },
    {
        path: "/app/stats/:uuid",
        element: <Stats />,
    },
]);
const helmetContext = {};

/*ReactDom.render(
    <HelmetProvider>
        <RouterProvider router={router} />
    </HelmetProvider>,
    document.getElementById("root")
)*/

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

const options = {
    api_host: global.config.REACT_APP_PUBLIC_POSTHOG_HOST,
}
root.render( <HelmetProvider context={helmetContext}>
    <PostHogProvider
        apiKey={global.config.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
    >
    <RouterProvider router={router} />
    </PostHogProvider>
</HelmetProvider>);

/*ReactDOM.createRoot(document.getElementById("root")).render(
    //<React.StrictMode>
    <HelmetProvider>
        <RouterProvider router={router} />
    </HelmetProvider>
    //</React.StrictMode>
);*/
