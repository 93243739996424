import Logo from '../asset/img/okurensiologo.svg';
import Loginimg from '../asset/img/loginimg.png';
import { Form } from "react-router-dom";
import Error from "../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { Navbar } from 'flowbite-react';
import Lang from '../component/lang.jsx'
import  Meta  from "../component/Meta";

import DetectLang from "./detectLang";
import Img1 from '../asset/img/architecture-2260836_640.jpg';
import Img2 from '../asset/img/hot-air-balloons-4561267_640.jpg';
import Img3 from '../asset/img/language-2345801_640.jpg';
import Img4 from '../asset/img/qrcode.jpg';
import Demo1 from '../asset/img/demo1.png';
import Demo2 from '../asset/img/demo2.png';
import Visit from '../asset/img/visit.png';
import Coeur from '../asset/img/coeur.png';
import CookieConsent from "react-cookie-consent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faCheck,
    faCircle,
    faCircleArrowRight,
    faInfo,
    faQuestion,
    faTree, faWarning
} from "@fortawesome/free-solid-svg-icons";

import mixpanel from "mixpanel-browser/src/loader-module";
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from "react-router-dom"
import i18n from "../component/i18n";
import { usePostHog } from 'posthog-js/react'


export default function Root() {

    const { t } = useTranslation();
    const posthog = usePostHog()
    const trackAutomaticEvents = false;

    const font = {
        fontFamily: "'Noto Sans', sans-serif",
    }

    const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };
    ReactPixel.init('698603254231987', advancedMatching, options);

    ReactPixel.pageView();

    const meta = {
        title: t('Livret Digital d\'accueil  un outil de bord incontournable'),
        description: t('Élevez l\'expérience de vos nouveaux arrivants à un autre niveau avec notre livret digital d\'accueil'),
        image: Demo1,
        font: "Noto+Sans:ital,wght@0,300;0,900;1,400"
    }

    const [langages, setLangage] = useState([]);
    useEffect(() => {


           var sc = document.createElement("script");
            sc.setAttribute("defer",true);
            sc.setAttribute("src","https://dbwx2z9xa7qt9.cloudfront.net/bundle.js?cachebust=1707741612420");
            sc.setAttribute("theme","dark");
            sc.setAttribute("footer", "false");
            sc.setAttribute("widget-type","carousel");
            sc.setAttribute("token","65b892009e2a72f323300085");
            sc.setAttribute('apiurl', "https://server.onlinereviews.tech/api/v0.0.9");
            sc.setAttribute('stats',"true");
            sc.setAttribute('addReview',"false");
            sc.setAttribute('profile-pic',"true");
            sc.setAttribute('review-name',"true");
            sc.setAttribute('wl', "true");
            sc.setAttribute('wlndig', "https://go.gestion-avis.fr/okurensio");
            sc.setAttribute('lang', "us");
            sc.setAttribute('brandStyle', "%7B%22sidebar_background%22%3A%22%23004AAD%22%2C%22sidebar_text%22%3A%22white%22%2C%22brand_button_text_color%22%3A%22white%22%2C%22brand_main_color%22%3A%22%23000000%22%2C%22brand_button_border_radius%22%3A%2220px%22%2C%22brand_button_hover%22%3A%22rgb(0%2C%200%2C%200)%22%2C%22brand_button_active%22%3A%22rgb(0%2C%200%2C%200)%22%2C%22brand_main_color_opacity%22%3A%22%230000001a%22%2C%22brand_font%22%3A%22Montserrat%22%2C%22star_color%22%3A%22%23128c7e%22%2C%22brand_main_background%22%3A%22%23F6F8F7%22%2C%22brand_card_background%22%3A%22white%22%2C%22poweredByLink%22%3A%22https%3A%2F%2Fgestion-avis.fr%22%2C%22poweredicon%22%3A%22https%3A%2F%2Frecensioni-io-static-folder.s3.eu-central-1.amazonaws.com%2Fpublic_onlinereviews%2Fapp.gestion-avis.fr%2Ftopbar.png%22%7D");
            document.getElementById("wid_1707741612420").appendChild(sc);



        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });

        posthog.capture(
            'front page accueil',
            {
                $set: { referer: document.referrer }
            }
        )
    }, []);

    return (
        <section  className="bg-hray-100 min-h-screen items-center justify-center flex flex-col  m-0">
            <Meta meta={meta} />
            <DetectLang />
                <div className={"flex justify-end gap-5 items-end w-full mt-10 mr-20"}>

                    <a href={"/login"} className={"hover:bg-cyan-700 text-cyan-700 hover:rounded  hover:text-white p-2 text-xs"}>{t('Me connecter')}</a>
                    <a href={"/articles"} className={"hover:bg-cyan-700 text-cyan-700 hover:rounded hover:text-white p-2 text-xs"}>{t('Articles')}</a>
                    <a href={"/tarif"} className={"hover:bg-cyan-700 text-cyan-700 hover:rounded hover:text-white p-2 text-xs"}>{t('Tarifs')}</a>
                    <Lang />
                </div>
            <div
                className="flex rounded-2xl  p-5 items-center flex-col container mt-10 mb-10">

                <div className={"flex flex-col sm:flex-row justify-center items-center "}>
                    <div className={"text-center"}> <img src={Logo} /></div>
                    <div className={"flex flex-col justify-center w-full"}>
                        <h1  style={{
                            fontFamily: "'Noto Sans', sans-serif",
                            fontWeight: 900
                        }} className={"font-bold text-4xl text-center m-5 text-gray-800"}>{t('Transformez vos locations en expériences inoubliables')}</h1>
                        <div className={" flex items-center justify-center flex-col"}>
                            <div style={{
                                fontWeight: 400
                            }}  className={"text-left mt-10 m-1  text-2xl text-gray-700"}>
                                {t('Réduisez les questions de vos visiteurs')}
                            </div>
                            <div style={{
                                fontWeight: 400
                            }}  className={"text-left m-1 text-2xl mt-5 text-gray-700"}>{t('Simplifiez la vie de vos invités en remplaçant les livrets papier par des guides numériques interactifs')}</div>
                            <div style={{
                                fontWeight: 400
                            }}  className={"text-left m-1 text-2xl mt-5 text-gray-600"}>{t(' Finis les papiers, protégez la nature et accueillez vos invités de manière écologique.')}</div>
                        </div>

                    </div>
                </div>

                <div className={""}>
                    <div className="flex flex-row flex-wrap  justify-center items-center mt-10  md:flex-nowrap lg:flex-nowrap">
                        <div >
                            <img src={Visit}  className={"rounded w-full"}/>
                        </div>
                        <div className={"flex flex-col "}>
                            <div>
                                <h2 style={{
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontWeight: 900
                                }}  className={"font-bold m-5 text-2xl text-gray-800"}>{t('A la découvert des environs')}</h2>
                                <div style={{
                                    fontWeight: 400
                                }}   className={"text-justify m-5 text-xl"}>{t('Ne manquez jamais une opportunité de partager les trésors locaux.')} </div>
                                <div style={{
                                    fontWeight: 400
                                }}  className={"text-justify m-5 text-xl"}>
                                    {t('Okurensio guide vos voyageurs vers les lieux à visiter')}</div>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl mb-20"}>
                                    {t('Les restaurants et activitées de la région sont ajouté à votre livret automatiquement à partir de l\'adresse de votre location.')}
                                </div>
                                <br/>
                                <a   href={"/register"} className={"bg-cyan-700 text-white hover:rounded pt-5 pb-5 pr-10 pl-10 sm:text-lg mt-30 ml-5 rounded  sm:font-extrabold font-normal"}>{t('Créer mon livret')}</a>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap  justify-center items-center mt-10 mb-10 m:flex-nowrap md:flex-nowrap lg:flex-nowrap">
                        <div className={"flex flex-col"}>
                            <div>
                                <h2 style={{
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontWeight: 900
                                }}  className={"font-bold m-5 text-2xl"}>{t('Vos coups de coeur')}</h2>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl"}>{t('Partagez vos recommandations personnelles.')} {t('Des endroits uniques, des expériences exclusives')}</div>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl mb-20"}>{t('Les plus beaux endroits à visiter ou à découvrir qui ne figure pas dans les guides.')}</div>
                                <br/>
                                <a   href={"/register"} className={"bg-cyan-700 text-white hover:rounded pt-5 pb-5 pr-10 pl-10 sm:text-lg mt-30 ml-5 w-full rounded sm:font-extrabold font-normal"}>{t('Créer mon livret')}</a>
                            </div>
                        </div>
                        <div >
                            <img src={Coeur}  className={"rounded w-full"}/>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap  justify-center items-center mt-10 m:flex-nowrap md:flex-nowrap lg:flex-nowrap">
                        <div >
                            <img src={Img3}  className={"rounded w-full"}/>
                        </div>
                        <div className={"flex flex-col"}>
                            <div>
                                <h2 style={{
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontWeight: 900
                                }}  className={"font-bold m-5 text-2xl"}>{t(' Traduction Automatique')}</h2>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl"}>{t('Okurensio parle plusieurs langues.')} {t('Notre application se traduit automatiquement, garantissant que chaque invité se sente chez lui, peu importe sa langue maternelle.')}</div>
                                <div style={{
                                    fontWeight: 400
                                }} className={"flex flex-col sm:flex-row gap-5 m-5 text-xl"}>
                                    {t('Actuellement disponible dans les langues suivante')} : <br/>
                                    {langages.map((langage) =>
                                            <img src={global.config.uriAPI+"/"+langage.flag} className={"w-4 h-4 rounded-full shadow-lg  m-1"} />
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap  justify-center items-center mt-10 m:flex-nowrap md:flex-nowrap lg:flex-nowrap">
                        <div className={"flex flex-col"}>
                            <div>
                                <h2 style={{
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontWeight: 900
                                }}  className={"font-bold m-5 text-2xl"}>{t('QR Codes Intelligents')}</h2>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl"}>{t('Vos visiteurs scannent simplement un QR code pour accéder au livret numérique personnalisé')}</div>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl"}>{t('Créez des QR codes pour expliquer le fonctionnement de certains appareils, comme une baignoire balnéothérapie, un four, pour une ')}<b className={"text-cyan-700"}>{t('expérience sans soucis')}</b></div>
                            </div>
                        </div>
                        <div >
                            <img src={Img4}  className={"rounded w-full"}/>
                        </div>
                    </div>

                    <div className={"flex flex-row flex-wrap  justify-center items-center mt-10 m:flex-nowrap md:flex-nowrap lg:flex-nowrap gap-5 "} >
                        <a href={"https://okurens.io/guidebook/e9c8b8ea-5898-4b5a-a8d2-45b379b410f5"} target={"_blank"}>
                            <img src={Demo1} className={"rounded"} />
                        </a>
                        <div className={"w-full"}>
                            <h2 style={{
                                fontFamily: "'Noto Sans', sans-serif",
                                fontWeight: 900
                            }} className={"font-bold m-5 text-2xl"}>{t('Un livret 100 % personnalisable')}</h2>
                            <div style={{
                                fontWeight: 400
                            }} className={"text-justify m-5 text-xl"}><FontAwesomeIcon icon={faCheck} /> {t('Couleurs et image de fond')}</div>
                            <div style={{
                                fontWeight: 400
                            }} className={"text-justify m-5 text-xl"}><FontAwesomeIcon icon={faCheck} /> {t('Catégories')}</div>
                            <div style={{
                                fontWeight: 400
                            }} className={"text-justify m-5 text-xl"}><FontAwesomeIcon icon={faCheck} /> {t('Nom de la location')}</div>
                            <div style={{
                                fontWeight: 400
                            }} className={"text-justify m-5 text-xl"}><FontAwesomeIcon icon={faCheck} /> {t('Logo')}</div>
                            <div style={{
                                fontWeight: 400
                            }} className={"text-justify m-5 text-xl"}><FontAwesomeIcon icon={faCheck} /> {t('Code Wifi')}</div>
                            <div style={{
                                fontWeight: 400
                            }} className={"text-justify m-5 text-xl"}><FontAwesomeIcon icon={faCheck} /> {t('Vos coups de coeurs (restaurants, activitées etc)')}</div>
                            <br/>
                            <div className={"text-center mt-25"}>
                                <a href={'/register'}  className={"bg-cyan-700 text-white hover:rounded pt-5 pb-5 pr-10 pl-10 sm:text-lg mt-30 ml-5 w-full rounded sm:font-extrabold font-normal"}>{t('Créer mon livret')}</a>
                            </div>
                        </div>
                    </div>
                    <div className={"bg-gray-200 rounded m-5"}>
                        <div id="wid_1707741612420"></div>
                    </div>

                    <div>
                        <div className="flex flex-row flex-wrap  justify-center items-center mt-10 m:flex-nowrap md:flex-nowrap lg:flex-nowrap">
                            <div className={"flex flex-col"}>
                                <div>
                                    <h2 style={{
                                        fontFamily: "'Noto Sans', sans-serif",
                                        fontWeight: 900
                                    }} className={"font-bold m-5 text-2xl"}>{t('Bienvenue / Welcome')}</h2>
                                    <div style={{
                                        fontWeight: 400
                                    }} className={"text-justify m-5 text-lg"}>{t('Personnalisez et imprimez votre affiche de bienvenue')}</div>
                                    <div style={{
                                        fontWeight: 400
                                    }} className={"text-justify m-5 text-lg"}>{t('Disposez la à l\'entrée de votre logement')}</div>
                                    <div style={{
                                        fontWeight: 400
                                    }} className={"text-justify m-5 text-lg"}>{t('Vos visiteurs accédent directement au code Wifi et au fonctionnement interne')}</div>
                                </div>
                            </div>
                            <div >
                                <img src={Demo2}  className={"rounded w-full"}/>
                            </div>
                        </div>
                    </div>
                    <div  className={"flex flex-col mt-5"}>
                        <h2 style={{
                            fontFamily: "'Noto Sans', sans-serif",
                            fontWeight: 900
                        }} className={"text-center font-bold text-3xl"}>{t('Pourquoi Okurensio')}</h2>
                        <div style={{
                            fontWeight: 400
                        }} className={"m-5 text-lg"}>{t('Okurensio est un dérivé du latin "occurrens" qui veut dire "aller au-devant / rencontrer".')}</div>
                        <div style={{
                            fontWeight: 400
                        }} className={"m-5 text-lg"}>{t('L\'idée m\'est venue lors de la rénovation de mes 2 gîtes dans la baie du Mont Saint Michel ( le Domaine des Figuiers actuellement en travaux).')} {t('Je voulais un moyen simple et autonome d\'accueillir mes futurs visiteurs tous en leur partageant ma région avec mes coups de coeurs et mes astuces pour un voyage réussi.')}</div>
                        <div style={{
                            fontWeight: 400
                        }} className={"m-5 text-lg"}>{t('Je voulais  un moyen efficace d\'agir  à ma façon pour la planète en évitant d\'imprimer des livres dans différentes langues ou pour chaque modification !!')}</div>
                    </div>
                </div>

                <a href={"/cgv"} className={"hover:bg-cyan-700 text-cyan-700 hover:rounded hover:text-white p-2 text-xs"}>{t('Mentions légales')}</a>
            </div>
            <CookieConsent
                location="bottom"
                buttonText={t('Accepter')}
                cookieName="okurensio"
                style={{ background: "#2B373B" }}
                buttonStyle={{ background: "#0097B2" , color: "#ffffff", fontSize: "13px" }}
                expires={150}
            >
                {t('Nous utilitons des cookies pour améliorer votre expérience d\'utilisation')}.{" "}
                <span style={{ fontSize: "10px" }}></span>
            </CookieConsent>
        </section>
    );
}