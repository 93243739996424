import Error from "../../../component/Error";
import React, {useState, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faArrowsUpDown,
    faForward,
    faBook,
    faSpinner,
    faArrowRightToBracket,
    faCogs,
    faDownload,
    faEdit,
    faAdd,
    faTrash,
    faCheck,
    faPlusSquare,
    faEye,
    faCaretDown,
    faHospital
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import Meta from "../../../component/Meta";
import GoogleMapReact from 'google-map-react';
import {Button, Card, Modal} from 'flowbite-react';
import SubCategory from './SubCategory';
import { Tooltip, Avatar } from 'flowbite-react';

export default function ShowGuideBook(props) {

    let { uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';

    const { t } = useTranslation();
    const meta = {
        title: t('Mon livret d\'accueil')
    }
    const [openModal, setOpenModal] = useState(false);
    const [guidebook, setGuidebook] = useState([]);
    const [categorys, setCategorys] = useState([]);

    const [defaultMap, setDefaultMap] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(50);
    const [orderBy, setOrderBy] = useState([]);
    const [searchName, setSearchName] = useState('');
    const [pathDelete, setPathDelete] = useState('');
    const [inDelete, setInDelete] = useState(0);
    const[reload, setReload] = useState(1);
    const [reloadBtnUrgence, setReloadBtnUrgence] = useState(0);

    const openModalDelete = (path) => {
        setPathDelete(path);
        setOpenModal(true)
    }

    const viewSubcategory = (event) => {

        const categoryUuid = event.target.getAttribute('categoryUuid');
        return false;
    }

    useEffect(() => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriAPI+'/app/guidebook/show/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setGuidebook(data)
                setDefaultMap({
                    "center": {
                        "lat": data.latitude,
                        "lng": data.longitude
                    },
                    "zoom": 11
                })


                var raw = JSON.stringify({"page": page,"limit": limit, "orderBy": orderBy, 'name': searchName});
                var requestOptions = {
                    method: 'POST',
                    body: raw,
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch(global.config.uriAPI+'/app/category/'+uuid, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        setCategorys(data)
                        setReload(0);
                    })
                    .catch((err) => {
                    });

            })
            .catch((err) => {
            });


    }, [reload]);

    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (id) => {
        dragItem.current = id;

    }

    const dragEnter = (id) => {
        dragOverItem.current = id;
        const nb = categorys.length;
        const boxes = document.querySelectorAll('.categoryBox');

        boxes.forEach(box => {

            box.classList.remove('border-t-2');

        });
        boxes.forEach(box => {

            box.classList.remove('border-b-2');

        });
        if (dragItem.current < dragOverItem.current) {

            document.getElementById("categoryBox_"+id).classList.add("border-b-2");
        } else {

            document.getElementById("categoryBox_"+id).classList.add("border-t-2");
        }


    }

    //https://medium.com/nerd-for-tech/simple-drag-and-drop-in-react-without-an-external-library-ebf1c1b809e
    const drop = () => {

        const copyListItems = [...categorys];
        const dragItemContent = copyListItems[dragItem.current];
        const dragOverItemContent = copyListItems[dragOverItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        const position = dragOverItem.current;
        var raw = JSON.stringify({"name": dragItemContent.name,"uuid": dragItemContent.uuid, "position": position+1});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/category/update', requestOptions)
            .then((response) => response.json())
            .then((data) => {
            })
            .catch((err) => {
            });

        dragItem.current = null;
        dragOverItem.current = null;
        const boxes = document.querySelectorAll('.categoryBox');

        boxes.forEach(box => {

            box.classList.remove('border-t-2');

        });
        setReload(1);
    }

    const deleteCall = () => {
        const path = pathDelete;
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        setInDelete(1);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+path, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setInDelete(0);
                setOpenModal(false);
                setReload(1);
            })
            .catch((err) => {
            });
    }

    const addUrgency = (event) =>
    {
        setReloadBtnUrgence(1);
        //  guidebook/addurgence/guidebookUUID
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        setInDelete(1);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/guidebook/addurgence/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setReload(1);
                setReloadBtnUrgence(0);
            })
            .catch((err) => {
            });
    }

    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <Meta meta={meta} />
            <div className="w-full">
                <Menu/>
            </div>
            <div className=" md:container mx-auto  flex items-center flex-col shadow rounded">
                {reload === 0 ?
                    guidebook.id != undefined ?
                            (
                                <div className={"grid grid-cols-1 w-full p-10"}>
                                    <div className={"flex items-center justify-start flex-row m-5  flex-wrap gap-2"}>
                                        <div className={"flex flex-col gap-2"}>
                                            <b className="first-letter:uppercase text-4xl ">{guidebook.name}</b>

                                            <p>{t('Adresse')} : <span>{guidebook.address}</span></p>
                                            <p>{t('Votre adresse email en cas de contact')} : <span>{guidebook.contactEmail}</span></p>
                                            <p>{t('Votre numéro en cas de contact')} : <span>{guidebook.contactPhone}</span></p>
                                            <p className={""}>
                                                <Button.Group>
                                                    <a href={"/app/guidebook/edit/"+guidebook.uuid} className={"text-center  col-span-1 border-cyan-700 text-cyan-500  py-1 rounded-md hover:bg-cyan-700  hover:text-white font-semibold p-2 text-xs"}>
                                                        <FontAwesomeIcon icon={faEdit} /> {t('Modifier')}</a>
                                                    <a href={"/app/guidebook/settings/"+guidebook.uuid} className={"text-center  col-span-1 border-cyan-700 text-cyan-500  py-1 rounded-md hover:bg-cyan-700  hover:text-white font-semibold p-2 text-xs"}>
                                                        <FontAwesomeIcon icon={faCogs} /> {t('Configuration')}</a>
                                                    <a href={"/guidebook/"+guidebook.uuid} target={"_blank"} className={"text-center  col-span-1 border-cyan-700 text-cyan-500  py-1 rounded-md hover:bg-cyan-700  hover:text-white font-semibold p-2 text-xs"}>
                                                        <FontAwesomeIcon icon={faEye} /> {t('Voir le livret')}</a>
                                                </Button.Group>
                                            </p>
                                        </div>
                                        <div className={"flex justify-end sm:justify-center"}>
                                            <img src={global.config.uriAPI + '/qrcode/guide-' +guidebook.uuid+'.png'} className={"w-1/2"}/>
                                        </div>

                                    </div>
                                    <div>
                                        <div style={{width:"100%",height:"250px", borderRadius: "10px;"}} className={"rounded"}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: global.config.apiKeyGoogle }}
                                                center={[guidebook.latitude, guidebook.longitude]} zoom={12}
                                            >
                                            </GoogleMapReact>
                                        </div>
                                    </div>
                                    <a href={"/app/guidebook/addcategory/"+guidebook.uuid} className={"text-center  col-span-1 border-cyan-700 text-cyan-500  py-1 rounded-md hover:bg-cyan-700  hover:text-white font-semibold p-2 text-lg m-5"}>
                                        <FontAwesomeIcon icon={faAdd} /> {t('Ajouter une categorie')}</a>
                                    <div className={"flex items-center justify-center gap-10 mb-20"}>
                                        {categorys.length >0 ? (
                                            <div className={"grid grid-cols-1 md:grid-cols-1 w-full "}>
                                                {categorys.map((category,index) =>
                                                    <div
                                                        onDragStart={()=>dragStart(index)}
                                                        onDragEnter={()=>dragEnter(index)}
                                                        onDragEnd={drop}
                                                        draggable
                                                        id={"categoryBox_"+index}
                                                        className={"categoryBox"}
                                                    >
                                                        <div className="shadow rounded flex flex-col gap-5 p-5 m-5"
                                                        >
                                                            <div className={"flex flex-row justify-between gap-5"}>
                                                                <div className={" text-xs  order-2 rounded p-2 text-gray-600 " +
                                                                    " hover:cursor-grab flex gap-2 flex-row " +
                                                                    "items-center justify-center bg-gray-100"}>
                                                                    <FontAwesomeIcon icon={faArrowsUpDown} className={"text-gray-500 dark:text-gray-400"} />
                                                                    <div id={"position_"+index}>{category.position}</div>
                                                                </div>

                                                                <Avatar img={global.config.uriAPI +"/"+ category.media_url} className={"order-1"}>
                                                                    <div className="space-y-1 font-medium dark:text-white">
                                                                        <a href="#" categoryId = {category.id} categoryUuid = {category.uuid} onClick={viewSubcategory} className={"first-letter:uppercase"}>{category.name}</a>
                                                                    </div>
                                                                </Avatar>
                                                            </div>
                                                            <SubCategory categoryUuid={category.uuid} guideBookUuid={guidebook.uuid} openModalDelete={openModalDelete}/>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ):  (<></>)}
                                        <br/>
                                    </div>
                                </div>
                            ): (<FontAwesomeIcon icon={faSpinner} spin={true} /> )
                    : <FontAwesomeIcon icon={faSpinner} spin={true} />}

                <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup >
                    <Modal.Header />
                    <Modal.Body>
                        <div className="text-center">
                            <FontAwesomeIcon icon={faCircleExclamation} className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                {t('Vous confirmer vouloir supprimer cette élèment ? ')}
                            </h3>
                            {inDelete === 0 ?
                                <div className="flex justify-center gap-4">
                                    <a href={"#"}
                                       data-uuid = {""}
                                       className={" border-2 border-red-700  text-red-700 red text-xs rounded p-1 flex items-center justify-center hover:bg-red-700 hover:text-white"} color="failure"
                                       onClick={() => deleteCall()}>
                                        {t('Oui, supprimer')}
                                    </a>
                                    <Button color="gray" onClick={() => setOpenModal(false)}>
                                        {t('Non')}
                                    </Button>
                                </div>
                                :
                                <div>
                                    <FontAwesomeIcon icon={faSpinner} spin size="2x"/> {t('Suppression en cours')}
                                </div>
                            }

                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </section>
    )
}