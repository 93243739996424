import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import {Breadcrumb, FileInput, Label, Select} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";


export default function AddCategory(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    let { uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [button, setButton] = useState({disabled : '', loader: ''});

    const [loadData, setLoadData] = useState(false);

    const [guideBook, setGuideBook] = useState([]);
    const [categorysDuplicate, setCategorysDuplicate] = useState([]);

    const { t } = useTranslation();
    const meta = {
        title: t('Ajouter une categorie')
    }
    const [inputs, setInputs] =  useState({ logo: '', name: ''});

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faAdd} />});

    const searchIcon = (event) => {
        const val = event.target.value;

        //https://api.freepik.com/v1/icons?term=voiture&family-id=3

        //x-freepik-api-key

        /*const myHeaders = new Headers();
       // myHeaders.append("Content-Type", "application/json");
        myHeaders.append('x-freepik-api-key', global.config.FREEPICK_API)
        myHeaders.append('Access-Control-Allow-Origin', '*');
        myHeaders.append('Access-Control-Allow-Headers','*');
        myHeaders.append('Access-Control-Allow-Methods','*');

        var requestOptions = {
            method: 'GET',
            headers: myHeaders
        };


        fetch('https://api.freepik.com/v1/icons?term='+val+'&family-id=3', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((err) => {
            });*/


    }

    useEffect(() => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriAPI+'/app/guidebook/show/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data);

                if (data.duplicateUUID !== null) {
                    var raw = JSON.stringify({"page":1 ,"limit": 200, "orderBy": [], 'name':'' });
                    var requestOptions = {
                        method: 'POST',
                        body: raw,
                        headers: myHeaders,
                        redirect: 'follow'
                    };
                    fetch(global.config.uriAPI+'/app/category/'+data.duplicateUUID, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            setCategorysDuplicate(data);
                        })
                        .catch((err) => {
                        });
                }
            })
            .catch((err) => {
            });

    }, []);
    const onSubmit = (data) => {

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        //myHeaders.append('Content-Type', 'Multipart/form-data');

        let formData = new FormData()

        let fileLogo = document.querySelector('#logo').files[0];
        if (fileLogo) {
            const fileSizeKiloBytesLogo = fileLogo.size / 1024
            const extensionLogo = fileLogo.type;


            if (['image/png', 'image/jpg', 'image/jpeg'].includes(extensionLogo) === false) {
                setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
                return;
            }

            if(fileSizeKiloBytesLogo > MAX_FILE_SIZE){
                setError('logo', { type: 'size', message: t('Le fichier est trop volumineux') });
                return
            }
        }

        setLoadData(false);

        formData.append('logo', fileLogo);
        formData.append('name', inputs.name);
        formData.append('categoryOverrideUUID', data.overrideUuid==undefined?'':data.overrideUuid);
        formData.append('guideBookUuid', uuid);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/category/create', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                    window.location.href = "/app/guidebook/show/"+uuid;
                    return;
            })
            .catch((err) => {
            });
    }


    const validLogo = () => {
        let fileData = document.querySelector('#logo').files[0];
        const extension = fileData.type;

        if (['image/png', 'image/jpg', 'image/jpeg'].includes(extension) === false) {
            setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
            return
        }
        setError('logo', null);
    }

    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <Meta meta={meta} />
            <div className="w-full">
                <Menu/>
            </div>
            <div className=" sm:container p-5">
                <Breadcrumb aria-label="Solid background breadcrumb example" className="bg-gray-50 px-5 py-3 dark:bg-gray-800">
                    <Breadcrumb.Item href="/app/index" >
                        {t('Accueil')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={"/app/guidebook/show/"+uuid}>{t('Livret')}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t('Ajouter')}</Breadcrumb.Item>
                </Breadcrumb>
                <Form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate id="formCreate" className={"flex flex-col gap-5"}>
                    <b>{t('Ajouter une catégorie pour votre livret')} : <span className={"first-letter:uppercase"}>{inputs.name}</span> </b>
                    <div id="fileUpload" className="w-full">
                        <div className="mb-2 block">
                            <Label htmlFor="file" value={t('Télécharger une image')} />
                        </div>
                        <FileInput id="logo"  name={"logo"}
                                   {...register("logo", { onChange: validLogo })}
                                   helperText={t('Vous pouvez mettre une image qui représente la categorie 400*400 pixel' )} />
                        {errors.logo &&  errors.logo.type === 'error' && (
                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {errors.logo.message}</div>
                        )}

                    </div>
                    <div className="mt-3 w-full">
                        <label htmlFor="name" className={ errors.name ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Le nom de la catégorie')}</label>
                        <input type="text" id="name"
                               name ="name"
                               aria-invalid={errors.name ? "true" : "false"}
                               defaultValue={inputs.name|| ""}
                               {...register("name", { onChange: handleChange, required: true})}
                               className= { errors.name ?
                                   "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                   "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 w-full"
                               }
                               placeholder={t("Nom de la catégorie")}/>
                        {errors.name && errors.name.type === "required" && (
                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                        )}
                    </div>
                    {guideBook.duplicateUUID != null ?
                        <div className="mt-3 w-full">
                            <label htmlFor="name" className={ errors.name ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Cette catégorie remplace la catégorie du livret parent ?')}</label>
                            <Select
                                id={"overrideUuid"}
                                name ="overrideUuid"
                                {...register("overrideUuid", { onChange: handleChange, required: true})}
                                aria-invalid={errors.name ? "true" : "false"}
                            >
                                <option value={""}>{t('Choisir une catégorie')}</option>
                                {categorysDuplicate.map((category,index) =>
                                    <option value={category.uuid}>{category.name}</option>)}
                            </Select>
                            {errors.overrideUuid && errors.overrideUuid.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                        </div>
                        : <></> }
                    <button type="submit" disabled={button.disabled}
                            className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                        {iconButton.icon}&nbsp;&nbsp; {t('Enregistrer')}
                    </button>
                </Form>
            </div>
        </section>
    )

}