import { Form } from "react-router-dom";
import Error from "../component/Error";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../asset/img/okurensiologo.svg';
import Loginimg from '../asset/img/loginimg.png';
import Lang from '../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {Navigate} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import * as amplitude from '@amplitude/analytics-browser';



export default function Login(props) {


    amplitude.init(global.config.amplitude);
    const [inputs, setInputs] =  useState({username : props.username, password : ''});
    const { t } = useTranslation();

    const [error, setError] = useState({typeError : '', messageError: '', iconFont : ''});
    const [button, setButton] = useState({disabled : '', loader: ''});
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faArrowRightToBracket} />});
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }

    const onSubmit = (data) => {

        //event.preventDefault();
        const username = data.username;
        const password = data.password;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({"username": username,"password": password});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        fetch(global.config.uriAPI+"/login_check", requestOptions)
            .then((response) =>  {
                if(response.status != 200) throw new Error(response.status);
                else return response.json();
            })
            .then(result => {
                    const token = result.token;
                    localStorage.setItem('token', token);
                    amplitude.setUserId(username);
                    localStorage.setItem('username', username);
                    amplitude.track('login');
                  //  mixpanel.track("login", { "email": username })
                    const name = '';
                    const value = '';
                    //setError(values => ({...values, [name]: value}))
                    setError(previousState => {
                        return { ...previousState, typeError: "success" }
                    });
                    setError(previousState => {
                        return { ...previousState, messageError: t('Authentification réussi') }
                    });

                window.location.href = "/app/home";
                return;
            })
            .catch((error) =>  {
                setError(previousState => {
                    return { ...previousState, typeError: "warning" }
                });
                setError(previousState => {
                    return { ...previousState, messageError: t('Authentification impossible')}
                });

                setError(previousState => {
                    return { ...previousState, iconFont:  <FontAwesomeIcon icon={faCircleExclamation} />}
                });
                setButton(previousState => {
                    return { ...previousState, disabled: '' }
                });
                setIconButton(previousState => {
                    return { ...previousState, icon:  <FontAwesomeIcon icon={faArrowRightToBracket} />}
                });
            });
    }

    return (
        <section className="bg-cyan-300 min-h-screen flex items-center justify-center">
            <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center">
                <div className="md:w-1/2 px-8 md:px-16">
                    <img src={Logo} />
                    <Error typeError={error.typeError} message={error.messageError} iconFont={error.iconFont} />
                    <Form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className="mt-3">
                            <label htmlFor="username" className={ errors.username ?  "block text-sm mb-2 text-red-700" : "block text-sm mb-2"}>{t('Adresse Email')}</label>
                            <input type="text" id="username"
                                   aria-invalid={errors.username ? "true" : "false"}
                                   defaultValue={inputs.username|| ""} onChange={handleChange}
                                   {...register("username", { required: true , pattern : /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g})}
                                   className= { errors.username ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                   }
                                   placeholder={t("Email...")}/>
                            {errors.username && errors.username.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                            {errors.username && errors.username.type === "pattern" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Mauvais format')}</div>
                            )}
                        </div>
                        <div className="mt-3">
                            <label htmlFor="password" className={ errors.password ?  "block text-sm mb-2 text-red-700" : "block text-sm mb-2"}>
                                {t('Mot de passe')}
                            </label>
                            <input type="password" id="password"
                                   defaultValue={inputs.password|| ""}
                                   onChange={handleChange}
                                   aria-invalid={errors.password ? "true" : "false"}
                                   {...register("password", { required: true })}
                                   className= { errors.password ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                       }
                                   placeholder={t('password')}/>
                                {errors.password && errors.password.type === "required" && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                )}
                        </div>
                        <div className="mt-5">
                            <button type="submit" disabled={button.disabled}
                                    className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                                {iconButton.icon}&nbsp;&nbsp;{t('Connection')}
                            </button>
                        </div>
                        <div className="mt-3 flex  flex-col justify-between items-center">
                            <div>
                                <a href="/lostpassword" className="text-black py-1 w-full text-xs
                                 font-semibold">{t('Mot de passe oublié')}?</a>
                            </div>
                            <div>
                                <a href="/register" className="text-black py-1 w-full text-xs
                                 font-semibold">{t('Créer un compte')}</a>
                            </div>
                            <div>
                                <a href="/" className="text-black py-1 w-full text-xs
                                 font-semibold">{t('Accueil')}</a>
                            </div>
                        </div>
                    </Form>
                    <Lang />
                </div>
                <div className="md:block hidden w-1/2">
                    <img className="rounded-2xl"
                         src={Loginimg} />
                </div>
            </div>
            <CookieConsent
                location="bottom"
                buttonText={t('Accepter')}
                cookieName="okurensio"
                style={{ background: "#2B373B" }}
                buttonStyle={{ background: "#0097B2" , color: "#ffffff", fontSize: "13px" }}
                expires={150}
            >
                {t('Nous utilitons des cookies pour améliorer votre expérience d\'utilisation')}.{" "}
                <span style={{ fontSize: "10px" }}></span>
            </CookieConsent>
        </section>
    )
}