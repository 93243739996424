import React, {useState, useEffect} from "react";
import {Avatar, Tooltip} from "flowbite-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faEdit, faPercent} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

export default function Topic(props) {
    const subCategoryUUid = props.subCategoryUuid;

    const [topics, setTopic] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [orderBy, setOrderBy] = useState([]);
    const [searchName, setSearchName] = useState('');
    const token = localStorage.getItem('token');
    const { t } = useTranslation();

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var raw = JSON.stringify({"page": page,"limit": limit, "orderBy": orderBy, 'title': searchName});
        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/topic/'+subCategoryUUid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setTopic(data);
                document.getElementById("subNb_"+subCategoryUUid).innerHTML = data.length;
            })
            .catch((err) => {
            });
    }, []);

    return (

        <div style={{ display: props.display}} id={"subTopic_"+subCategoryUUid} data-nb={topics.length}>

            {topics.map((topic) =>
                        <div className={"flex gap-5 m-2 items-center bg-gray-50 p-2 rounded text-gray-700 italic"}>
                            {new RegExp("places.google", 'g').test(topic.media_url) ?
                                <div style={{
                                    backgroundImage: `url(${topic.media_url})`,
                                    backgroundSize: "cover",
                                    width: 40,
                                    height: 40,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    borderRadius: 10,
                                    display: 'block'
                                }}>
                                </div>
                               :
                                <div style={{
                                    backgroundImage: `url(${global.config.uriAPI+"/"+topic.media_url})`,
                                    backgroundSize: "cover",
                                    width: 40,
                                    height: 40,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    borderRadius: 10,
                                    display: 'block'
                                }}>
                                </div>}

                            <div className={"flex flex-col gap-4"}>
                                <div className={"flex gap-10"}><b className={"text-xs"}>{topic.title}</b>
                                    {topic.type === 'google_place' ?
                                        <span  className={"bg-blue-300 p-1 text-xs rounded text-white w-fit"}>{topic.distance !== undefined ? topic.distance > 1000 ? topic.distance/1000 + ' km' : topic.distance + 'm' : <></>}</span>
                                        : <></>}
                                </div>
                                {topic.type === 'google_place' ? <p className={"text-xs"}>{topic.address}</p>:  <></>}
                                <div className={"flex flex-row gap-2"}>

                                        <a href={"/app/guidebook/edittopic/"+props.guideBookUuid+"/"+topic.uuid}
                                           className={"text-white bg-gray-700 p-1 rounded text-xs"} ><FontAwesomeIcon icon={faEdit}/> </a>

                                         <a href={"/app/guidebook/discountcodetopic/"+props.guideBookUuid+"/"+topic.uuid}
                                            className={topic.hasDiscountCode === true ? "text-white bg-green-700 p-1 rounded text-xs" : "text-white bg-gray-700 p-1 rounded text-xs"} ><FontAwesomeIcon icon={faPercent}/> </a>

                                        <a href={"#"} className={"text-white bg-red-700 p-1 rounded text-xs"} onClick={() => props.openModalDelete("/app/topic/remove/"+topic.uuid)}><FontAwesomeIcon icon={faTrash}/> </a>

                                </div>

                            </div>
                        </div>
                    )}


        </div>
    )
}