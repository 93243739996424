import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import {FileInput, Label} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyCustomUploadAdapterPlugin from "../../../component/PluginUploader";
import TopicEditContent from "./topic/topicEditContent";
import TopicEditWifi from "./topic/topicEditWifi";
import TopicEditPlace from "./topic/topicEditPlace";
import TopicEditElectrical from "./topic/topicEditElectrical";
import { Breadcrumb } from 'flowbite-react';

export default function EditTopic(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    let { guideBookUuid, uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [contentType, setContentType] = useState('');
    const [topic, setTopic] = useState([]);

    const { t } = useTranslation();
    const meta = {
        title: t('Editer un topic')
    }

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/topic/show/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setTopic(data);
                setContentType(data.type);
            })
            .catch((err) => {
            });
    }, []);

    return (
        <section className="bg-white flex items-center flex-col overflow-y-scroll h-full mb-20">
            <Meta meta={meta} />
            <div className="w-full">
                <Menu/>
            </div>
            <div className=" sm:container overflow-auto mx-auto w-full p-10 ">
                <Breadcrumb aria-label="Solid background breadcrumb example" className="bg-gray-50 px-5 py-3 dark:bg-gray-800">
                    <Breadcrumb.Item href="/app/home" >
                        {t('Accueil')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={"/app/guidebook/show/"+guideBookUuid}>{t('Livret')}</Breadcrumb.Item>
                    <Breadcrumb.Item>{topic.title}</Breadcrumb.Item>
                </Breadcrumb>
                {contentType === 'content' ? <TopicEditContent topic={topic} guideBookUuid = {guideBookUuid}/>: <></>}
                {contentType === 'wifi' ? <TopicEditWifi topic={topic} guideBookUuid = {guideBookUuid}/>: <></>}
                {contentType === 'google_place' ? <TopicEditPlace topic={topic} guideBookUuid = {guideBookUuid}/>: <></>}
                {contentType === 'electrical_appliance' ? <TopicEditElectrical topic={topic} guideBookUuid = {guideBookUuid}/>: <></>}
            </div>
        </section>
    )

}