import Error from "../../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../../asset/img/loginimg.png';
import Lang from '../../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../../component/i18n";
import  Meta  from "../../../../component/Meta";
import {FileInput, Label} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyCustomUploadAdapterPlugin from "../../../../component/PluginUploader";


export default function TopicEditPlace(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    const lng = i18n.language;
    const [infoPlace, setInfoPlace] = useState(null);
    let { guideBookUuid, subCategoryUuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [contentType, setContentType] = useState('content');
    const [button, setButton] = useState({disabled : '', loader: ''});

    const [loadData, setLoadData] = useState(false);
    const [summary, setSummary] = useState(props.topic.content);
    const { t } = useTranslation();
    const meta = {
        title: t('Ajouter un contenu')
    }
    const [inputs, setInputs] =  useState({ logo: '', name: props.topic.title});

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faAdd} />});
    const onSubmitContent = (data) => {

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        //myHeaders.append('Content-Type', 'Multipart/form-data');

        let formData = new FormData()

        let fileLogo = document.querySelector('#logo').files[0];
        if (fileLogo) {
            const fileSizeKiloBytesLogo = fileLogo.size / 1024
            const extensionLogo = fileLogo.type;
            console.log(fileLogo.width);

            if (['image/png', 'image/jpg', 'image/jpeg'].includes(extensionLogo) === false) {
                setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
                return;
            }

            if(fileSizeKiloBytesLogo > MAX_FILE_SIZE){
                setError('logo', { type: 'size', message: t('Le fichier est trop volumineux') });
                return
            }
        }

        setLoadData(false);

        formData.append('logo', fileLogo);
        formData.append('title', inputs.name);
        formData.append('content', summary);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/topicplace/update/'+props.topic.uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                window.location.href = "/app/guidebook/show/"+guideBookUuid;
                return;
            })
            .catch((err) => {
            });



    }


    const validLogo = () => {
        let fileData = document.querySelector('#logo').files[0];
        const extension = fileData.type;

        if (['image/png', 'image/jpg', 'image/jpeg'].includes(extension) === false) {
            setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
            return
        }
        setError('logo', null);
    }

    return (
        <Form action="" method="post" onSubmit={handleSubmit(onSubmitContent)} noValidate id="formCreate" className={"flex flex-col gap-5 mt-5"}>
            <b>{t('Ajouter un lieu')} : <span className={"first-letter:uppercase"}>{inputs.name}</span> </b>
            <div id="fileUpload" className="w-full">
                <div className="mb-2 block">
                    <Label htmlFor="file" value={t('Télécharger une image')} />
                </div>
                <FileInput id="logo"  name={"logo"}
                           {...register("logo", { onChange: validLogo })}
                           helperText={t('Vous pouvez mettre une image qui représente le lieu 400*400 pixel' )} />
                {errors.logo &&  errors.logo.type === 'error' && (
                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {errors.logo.message}</div>
                )}
                {props.topic.media !== null ?
                    (new RegExp("places.google", 'g').test(props.topic.media.url) ?
                            <img src={props.topic.media.url} className={"w-20 h-20 rounded-full mt-3"}/>:
                        <img src={global.config.uriAPI+"/"+props.topic.media.url} className={"w-20 h-20 rounded-full mt-3"}/>)
                    :  <></>}
            </div>
            <div className="mt-3 w-full">
                <label htmlFor="name" className={ errors.name ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Le nom')}</label>
                <input type="text" id="name"
                       name ="name"
                       aria-invalid={errors.name ? "true" : "false"}
                       defaultValue={inputs.name|| ""}
                       {...register("name", { onChange: handleChange, required: true})}
                       className= { errors.name ?
                           "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                           "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                       }
                       placeholder={t("Nom de la catégorie")}/>
                {errors.name && errors.name.type === "required" && (
                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                )}
            </div>
            <div className="mt-3 overflow-auto max-h-96">
                <label className={ errors.summary ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('La description du lieu')}</label>
                <CKEditor
                    editor={ ClassicEditor }
                    config = {{
                        extraPlugins: [ MyCustomUploadAdapterPlugin ],
                        placeholder: t('Description'),

                    }}
                    type="text"
                    name="summary"
                    data={summary}
                    onChange={ ( event, editor ) => { setSummary(editor.getData())} }
                />
                {errors.summary && errors.summary.type === "required" && (
                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                )}
            </div>
            <b>{t('Adresse')}</b> :  {props.topic.address} <br />
            {props.topic.uri !== '' ? <div><b>{t('Site internet')}</b> {props.topic.uri}</div>: <></>}
            {props.topic.phoneNumber !== '' ? <div><b>{t('Site Télèphone')}</b> {props.topic.phoneNumber}</div>: <></>}

            <button type="submit" disabled={button.disabled}
                    className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                {iconButton.icon}&nbsp;&nbsp; {t('Modifier')}
            </button>
        </Form>
    )

}