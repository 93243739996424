import {useParams} from "react-router-dom";
import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSquarePlus,
    faLink,
    faTrash,
    faEdit,
    faSpinner,
    faCog,
    faCircleExclamation, faCheck, faArrowRightToBracket, faHome, faMap, faSearch, faCircleInfo, faMapPin, faLocationDot
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import Meta from "../../../component/Meta";
import cadreBois from '../../../asset/img/cadrebois.png';
import { Page, Text, View, Document, StyleSheet, Image , Canvas} from '@react-pdf/renderer';

import {QRCodeSVG} from 'qrcode.react';
import { PDFViewer } from '@react-pdf/renderer';


export default function QrCode(props) {

    let { uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const { t } = useTranslation();

    const meta = {
        title: t('Affiche QRCode'),
    }

    const [guideBook, setGuideBook] = useState({
        link : ''
    });

    useEffect(() => {
        const myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/guidebook/pdf/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setGuideBook({
                    link: data.url,
                });
            })
            .catch((err) => {
            });



    },[]);


    return (
        <section className="bg-white flex items-center flex-col  min-h-screen">
            <Meta meta={meta} />
            <div className="w-full">
                <Menu/>
            </div>
            <div className="md:container mx-auto w-full flex items-center flex-col">
                <a href={global.config.uriAPI+'/'+guideBook.link} target={"_blank"}>telecharger</a>
            </div>
        </section>
    );
}